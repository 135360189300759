export const COURSE_CLAIM_STATUS = {
  INVALID: 'invalid',
  INVITING: 'inviting',
  SCHEDULED: 'scheduled',
} as const;

export const TRIAL_COURSE_TEACHER_GENDER = {
  MALE: 'male',
  FEMALE: 'female',
  BOTH: 'both',
} as const;
