import { teachersManagementAuth } from 'constants/auth/auth';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import path, {
  Auth,
  FirstRoute,
  Route,
  SecondRoute,
  ThirdRoute,
  teacherApplicationRead,
  teacherApplicationReadUnverified,
  teacherContractRead,
  teacherListRead,
  teacherMaterialRead,
} from '../path';
import { Loadable } from '../utils';

const TeachersManagement = Loadable(
  lazy(() => import('pages/teachersmanagement/TeachersManagement')),
);
const TeacherList = Loadable(
  lazy(() => import('pages/teachersmanagement/teachers/TeacherList/index')),
);
const TeacherForm = Loadable(
  lazy(() => import('pages/teachersmanagement/teachers/TeacherForm')),
);
const TeacherViewForm = Loadable(
  lazy(() => import('pages/teachersmanagement/teachers/TeacherViewForm/index')),
);
const TagList = Loadable(
  lazy(() => import('pages/teachersmanagement/teachers/TagList')),
);
const TeacherMaterial = Loadable(
  lazy(
    () => import('pages/teachersmanagement/teachermaterial/TeacherMaterial'),
  ),
);
const TeacherListInterview = Loadable(
  lazy(
    () =>
      import('pages/teachersmanagement/teacherinterview/TeacherInterviewList'),
  ),
);
const TeacherUnverifiedList = Loadable(
  lazy(
    () =>
      import('pages/teachersmanagement/teacherUnverified/TeacherInterviewList'),
  ),
);
const ContractSetting = Loadable(
  lazy(() => import('pages/teachersmanagement/setting/components/Setting')),
);
const SeriesDetail = Loadable(
  lazy(
    () =>
      import('pages/teachersmanagement/teachermaterial/series/SeriesDetail'),
  ),
);
const SeriesForm = Loadable(
  lazy(
    () => import('pages/teachersmanagement/teachermaterial/series/SeriesForm'),
  ),
);
const ReferrersList = Loadable(
  lazy(() => import('pages/teachersmanagement/referrers/ReferrerList')),
);

const ApplicantForm = Loadable(
  lazy(
    () => import('pages/teachersmanagement/teacherinterview/applicantDetaild'),
  ),
);
const ApplicantUnverifiedForm = Loadable(
  lazy(
    () => import('pages/teachersmanagement/teacherUnverified/applicantDetaild'),
  ),
);
const TeacherContractForm = Loadable(
  lazy(
    () =>
      import('pages/teachersmanagement/setting/components/TeacherContractForm'),
  ),
);
const TerminatedContractForm = Loadable(
  lazy(
    () =>
      import(
        'pages/teachersmanagement/setting/components/TerminatedContractForm'
      ),
  ),
);
const ResourceForm = Loadable(
  lazy(
    () =>
      import('pages/teachersmanagement/teachermaterial/resource/ResourceForm'),
  ),
);
const ExamForm = Loadable(
  lazy(() => import('pages/teachersmanagement/teachermaterial/exam/ExamForm')),
);
const HomeworkForm = Loadable(
  lazy(
    () =>
      import('pages/teachersmanagement/teachermaterial/homework/HomeworkForm'),
  ),
);
const InteractiveLearningForm = Loadable(
  lazy(
    () =>
      import(
        'pages/teachersmanagement/teachermaterial/interactive-learning/interactive-learning-form'
      ),
  ),
);

const generateTeachersManagementRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(teachersManagementAuth)) {
    return null;
  }

  return {
    path: Route.teachersmanagement,
    element: <TeachersManagement />,
    children: (() => {
      const firstFolder: RouteObject[] = [
        {
          element: (
            <Navigate
              to={(() => {
                if (includeAuth(teacherListRead)) {
                  return path.TEACHER_MANAGEMENT_LIST;
                }
                if (includeAuth(teacherApplicationRead)) {
                  return path.TEACHER_INTERVIEW_MANAGEMENT_LIST;
                }
                if (includeAuth(teacherApplicationReadUnverified)) {
                  return path.TEACHER_UNVERIFIED_MANAGEMENT_LIST;
                }
                if (includeAuth(teacherMaterialRead)) {
                  return path.TEACHER_MANAGEMENT_MATERIAL;
                }
                if (includeAuth(teacherContractRead)) {
                  return path.TEACHER_SETTING;
                }
                return path.PERMISSION_ABNORMAL;
              })()}
            />
          ),
          index: true,
        },
      ];

      includeAuth(teacherListRead) &&
        firstFolder.push({
          path: FirstRoute.teacherlist,
          children: (() => {
            const secondFolder: RouteObject[] = [];

            secondFolder.push(
              {
                element: <TeacherList />,
                index: true,
              },
              {
                path: SecondRoute.view,
                children: [
                  {
                    element: <Navigate to={Route.notFound} />,
                    index: true,
                  },
                  {
                    path: Route.id,
                    element: <TeacherViewForm />,
                  },
                ],
              },
              {
                path: SecondRoute.taglist,
                element: <TagList />,
              },
            );

            includeAuth([Auth.TEACHER_CREATE]) &&
              secondFolder.push({
                path: SecondRoute.create,
                element: <TeacherForm />,
              });

            includeAuth([Auth.TEACHER_EDIT]) &&
              secondFolder.push({
                path: SecondRoute.edit,
                children: [
                  {
                    element: <Navigate to={Route.notFound} />,
                    index: true,
                  },
                  {
                    path: Route.id,
                    element: <TeacherForm />,
                  },
                ],
              });

            return secondFolder;
          })(),
        });

      includeAuth(teacherMaterialRead) &&
        firstFolder.push({
          path: FirstRoute.teachermaterial,
          children: (() => {
            const secondFolder: RouteObject[] = [];

            secondFolder.push(
              {
                element: <TeacherMaterial />,
                index: true,
              },
              {
                path: SecondRoute.seriesread,
                children: [
                  {
                    element: <Navigate to={Route.notFound} />,
                    index: true,
                  },
                  {
                    path: Route.code,
                    element: <SeriesDetail />,
                  },
                ],
              },
            );

            secondFolder.push(
              {
                path: SecondRoute.seriescreate,
                element: <SeriesForm />,
              },
              {
                path: SecondRoute.resourcecreate,
                element: <ResourceForm />,
              },
            );
            includeAuth([Auth.MATERIAL_GAME_CREATE]) &&
              secondFolder.push({
                path: SecondRoute.interactivelearningcreate,
                element: <InteractiveLearningForm />,
              });

            secondFolder.push(
              {
                path: SecondRoute.seriesedit,
                children: [
                  {
                    element: <Navigate to={Route.notFound} />,
                    index: true,
                  },
                  {
                    path: Route.code,
                    element: <SeriesForm />,
                  },
                ],
              },
              {
                path: SecondRoute.resourceedit,
                children: [
                  {
                    element: <Navigate to={Route.notFound} />,
                    index: true,
                  },
                  {
                    path: Route.code,
                    element: <ResourceForm />,
                  },
                ],
              },
              {
                path: SecondRoute.examedit,
                children: [
                  {
                    element: <Navigate to={Route.notFound} />,
                    index: true,
                  },
                  {
                    path: Route.code,
                    element: <ExamForm />,
                  },
                ],
              },
              {
                path: SecondRoute.homeworkedit,
                children: [
                  {
                    element: <Navigate to={Route.notFound} />,
                    index: true,
                  },
                  {
                    path: Route.code,
                    element: <HomeworkForm />,
                  },
                ],
              },
              {
                path: SecondRoute.interactivelearningedit,
                children: [
                  {
                    element: <Navigate to={Route.notFound} />,
                    index: true,
                  },
                  {
                    path: Route.id,
                    element: <InteractiveLearningForm />,
                  },
                ],
              },
            );
            includeAuth([Auth.MATERIAL_GAME_EDIT]) &&
              secondFolder.push({
                path: SecondRoute.interactivelearningedit,
                children: [
                  {
                    element: <Navigate to={Route.notFound} />,
                    index: true,
                  },
                  {
                    path: Route.id,
                    element: <InteractiveLearningForm />,
                  },
                ],
              });
            return secondFolder;
          })(),
        });

      includeAuth(teacherApplicationRead) &&
        firstFolder.push({
          path: FirstRoute.teacherinterviewlist,
          children: [
            {
              element: <TeacherListInterview />,
              index: true,
            },
            {
              path: SecondRoute.applicantView,
              children: [
                {
                  element: <Navigate to={Route.notFound} />,
                  index: true,
                },
                {
                  path: Route.code,
                  element: <ApplicantForm />,
                },
              ],
            },
          ],
        });

      includeAuth(teacherApplicationReadUnverified) &&
        firstFolder.push({
          path: FirstRoute.teacherunverifiedlist,
          children: [
            {
              element: <TeacherUnverifiedList />,
              index: true,
            },
            {
              path: SecondRoute.applicantView,
              children: [
                {
                  element: <Navigate to={Route.notFound} />,
                  index: true,
                },
                {
                  path: Route.code,
                  element: <ApplicantUnverifiedForm />,
                },
              ],
            },
          ],
        });

      includeAuth(teacherApplicationReadUnverified) &&
        firstFolder.push({
          path: FirstRoute.referrersList,
          children: [
            {
              element: <ReferrersList />,
              index: true,
            },
          ],
        });

      includeAuth(teacherContractRead) &&
        firstFolder.push({
          path: FirstRoute.contract,
          children: (() => {
            const secondFolder: RouteObject[] = [];
            secondFolder.push({
              index: true,
              element: <Navigate to={path.TEACHER_CONTRACT_SETTING} />,
            });
            secondFolder.push({
              path: SecondRoute.teacherContract,
              children: (() => {
                const thirdFolder: RouteObject[] = [];
                thirdFolder.push({
                  index: true,
                  element: <ContractSetting />,
                });
                thirdFolder.push({
                  path: ThirdRoute.edit,
                  element: <TeacherContractForm />,
                });
                return thirdFolder;
              })(),
            });
            secondFolder.push({
              path: SecondRoute.terminatedContract,
              children: (() => {
                const thirdFolder: RouteObject[] = [];
                thirdFolder.push({
                  index: true,
                  element: <ContractSetting />,
                });
                thirdFolder.push({
                  path: ThirdRoute.edit,
                  element: <TerminatedContractForm />,
                });
                return thirdFolder;
              })(),
            });
            return secondFolder;
          })(),
        });

      return firstFolder;
    })(),
  };
};

export { generateTeachersManagementRoutes };
