import { EOrderType } from 'constants/customer.constants';
import { AxiosInstance } from 'axios';
import { TApiResponse } from 'src/@type/common';
import { IGetEmailTrackingParams, IGetEmailTrackingResponse } from './type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /**
   * @param orderId 訂單id
   * @description 寄送合約即將逾期的通知信＆簡訊
   */
  sendOverdueNotifications(orderId: string): TApiResponse<boolean> {
    return axios.post(
      `${apiPrefix}/orders/contracts/overdue/notifications/${orderId}`,
    );
  },
  /**
   * @param orderId 訂單id
   * @param orderType 訂單狀態
   * @description 寄送「繳款逾期催繳」or「繳款逾期帳號停用」的通知信＆簡訊
   */
  sendPaymentOverdueNotifications(
    orderId: string,
    orderType: EOrderType,
  ): TApiResponse<any> {
    return axios.post(
      `${apiPrefix}/orders/payments/${orderType}/notifications/${orderId}`,
    );
  },
  /**
   * @param orderId 訂單id
   * @description 取得訂單郵件追蹤（目前只追蹤逾期系列）
   */
  getEmailTracking(
    orderId: string,
    params: IGetEmailTrackingParams,
  ): TApiResponse<IGetEmailTrackingResponse> {
    return axios.get(`${apiPrefix}/orders/${orderId}/EmailTracking`, {
      params,
    });
  },
});
