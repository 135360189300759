import { AxiosInstance } from 'axios';
import order from './order';
import deliverExtraContent from './backend/deliver-extra-content';
import deliveries from './backend/deliveries';
import exchangeItemsRecords from './backend/exchange-items-records';
import deliveryEvents from './backend/delivery-events';
import invoice from './invoice';
import drawdown from './drawdown';
import drawdownRecord from './drawdownRecord';

/**
 * Swagger: https://api-dev.oneclass.co/product/v1/api/docs/
 */
export default (axios: AxiosInstance, apiPrefix: string) => ({
  order: order(axios, apiPrefix),
  invoice: invoice(axios, apiPrefix),
  backend: {
    deliverExtraContent: deliverExtraContent(axios, apiPrefix),
    deliveries: deliveries(axios, apiPrefix),
    exchangeItemsRecords: exchangeItemsRecords(axios, apiPrefix),
    deliveryEvents: deliveryEvents(axios, apiPrefix),
  },
  drawdown: drawdown(axios, apiPrefix),
  drawdownRecord: drawdownRecord(axios, apiPrefix),
});
