import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';

import {
  ICreateLearningCoachIdPayload,
  ICreateLearningCoachIdResponse,
  IGetLearningCoachParams,
  IGetLearningCoachResponse,
  ILearningCoachScheduleWeekPayload,
  ILearningCoachScheduleWeekResponse,
  ILearningCoachWeekContentPayload,
  ILearningCoachWeekContentResponse,
} from './learning-coach.type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  // 取得學習教練內容
  getLearningCoach(
    params: IGetLearningCoachParams,
  ): TApiResponse<IGetLearningCoachResponse> {
    return axios.get(`${apiPrefix}/learning-coach`, { params });
  },

  // 產生學習教練 Id
  createLearningCoachId(
    payload: ICreateLearningCoachIdPayload,
  ): TApiResponse<ICreateLearningCoachIdResponse> {
    return axios.post(`${apiPrefix}/learning-coach`, payload);
  },

  // 取得學校課程計畫全部的週次
  getLearningCoachScheduleWeek(
    params: ILearningCoachScheduleWeekPayload,
  ): TApiResponse<ILearningCoachScheduleWeekResponse> {
    return axios.get(`${apiPrefix}/learning-coach/member/schedule-week`, {
      params,
    });
  },

  // 取得選擇週次的學習教練內容
  getLearningCoachWeekContent(
    params: ILearningCoachWeekContentPayload,
  ): TApiResponse<ILearningCoachWeekContentResponse> {
    return axios.get(`${apiPrefix}/learning-coach/member/week-content`, {
      params,
    });
  },
});
