import { auditionAuth } from 'constants/auth/auth';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import path, {
  Route,
  FirstRoute,
  SecondRoute,
  auditionRead,
  auditionVipPass,
  Auth,
  afterClassStudyRead,
  courseTagRead,
  courseFormalAuditionRead,
  courseCreate,
  courseEdit,
  courseRead,
  auditionCourseRead,
  realtimeCourseRead,
  batchScheduledCoursesRead,
  nowCoursePreClassEdit,
  nowCourseFormalEdit,
  courseReviewRead,
  leaveRead,
  auditionLearningCoach,
} from 'routes/path';
import { ReservationCourseProvider } from 'services/ReservationCourseService';
import { Loadable } from '../../utils';
import { generateCourseClaimRoutes } from './course-claim';

const Audition = Loadable(lazy(() => import('pages/audition/Audition')));
const AuditionReservation = Loadable(
  lazy(() => import('pages/audition/reservation/Reservation')),
);
const AuditionLearningCoach = Loadable(
  lazy(() => import('pages/audition/learning-coach/learning-coach')),
);
const AuditionLearningCoachForm = Loadable(
  lazy(() => import('pages/audition/learning-coach/learning-coach-form')),
);
const AuditionLearningPlan = Loadable(
  lazy(
    () => import('pages/audition/learning-coach/learning-plan/learning-plan'),
  ),
);
const AuditionVipPass = Loadable(
  lazy(() => import('pages/audition/vippass/VipPass')),
);
const AuditionCourse = Loadable(
  lazy(() => import('pages/audition/course/Course')),
);
const AuditionCourseManagement = Loadable(
  lazy(() => import('pages/audition/course/course-management')),
);
const NowCourse = Loadable(
  lazy(() => import('pages/audition/oneclass-now/now-course')),
);
const NowCourseManagement = Loadable(
  lazy(() => import('pages/audition/oneclass-now/course-management')),
);
const NowCourseForm = Loadable(
  lazy(() => import('pages/audition/oneclass-now/course-form')),
);
const NowCourseDetail = Loadable(
  lazy(() => import('pages/audition/oneclass-now/course-detail')),
);
const NowCourseFeedback = Loadable(
  lazy(() => import('pages/audition/oneclass-now/course-feedback')),
);
const AuditionTrialCourse = Loadable(
  lazy(() => import('pages/audition/trialcourse/TrialCourse')),
);
const AuditionTrialCourseItem = Loadable(
  lazy(
    () => import('pages/audition/trialcourse/trialCourseForm/TrialCourseForm'),
  ),
);
const AuditionCourseItem = Loadable(
  lazy(() => import('pages/audition/course/course-form/course-form')),
);
const AuditionCourseDetail = Loadable(
  lazy(() => import('pages/audition/course/course-detail/course-detail')),
);
const AuditionPublicCourse = Loadable(
  lazy(() => import('pages/audition/publicCourse/PublicCourse')),
);
const AuditionPublicCourseItem = Loadable(
  lazy(
    () =>
      import('pages/audition/publicCourse/publicCourseForm/PublicCourseForm'),
  ),
);
const BatchScheduledCourse = Loadable(
  lazy(
    () => import('pages/audition/batchScheduledCourse/BatchScheduledCourse'),
  ),
);
const BatchScheduledCourseForm = Loadable(
  lazy(
    () => import('pages/audition/batchScheduledCourse/component/CourseForm'),
  ),
);
const BatchScheduledCourseDetail = Loadable(
  lazy(
    () => import('pages/audition/batchScheduledCourse/component/CourseDetail'),
  ),
);
const BatchScheduledCopyCourseForm = Loadable(
  lazy(
    () =>
      import('pages/audition/batchScheduledCourse/component/CopyCourseForm'),
  ),
);
const AuditionRealtimeCourse = Loadable(
  lazy(() => import('pages/audition/realtimecourse/RealtimeCourse')),
);

const AuditionCourseFeedback = Loadable(
  lazy(() => import('pages/audition/course/courseFeedback/CourseFeedback')),
);
const Coursetag = Loadable(
  lazy(() => import('pages/audition/coursetag/Coursetag')),
);
const OmsCourse = Loadable(
  lazy(() => import('pages/audition/omsCourse/OmsCourse')),
);

const CourseReview = Loadable(
  lazy(() => import('pages/audition/courseReview/CourseReview')),
);
const ReviewForm = Loadable(
  lazy(() => import('pages/audition/courseReview/ReviewForm/ReviewForm')),
);
const TutorialCenterCourseItem = Loadable(
  lazy(() => import('pages/tutorialCenter/formalCourse/courseForm/CourseForm')),
);
const Leave = Loadable(lazy(() => import('pages/audition/leave/Leave')));
const LeaveDetail = Loadable(
  lazy(() => import('pages/audition/leave/LeaveDetail')),
);

const generateAuditionRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(auditionAuth)) return null;

  return {
    path: Route.audition,
    element: <Audition />,
    children: (() => {
      const firstFolder: RouteObject[] = [
        {
          element: (
            <Navigate
              to={(() => {
                if (includeAuth(auditionLearningCoach)) {
                  return path.AUDITION_LEARNING_COACH;
                }
                if (includeAuth(auditionVipPass)) {
                  return path.AUDITION_VIP_PASS;
                }
                if (includeAuth(auditionRead)) {
                  return path.AUDITION_RESERVATION;
                }
                if (includeAuth(auditionCourseRead)) {
                  return path.AUDITION_TRIAL_COURSE;
                }
                if (includeAuth(courseFormalAuditionRead)) {
                  return path.AUDITION_COURSE;
                }
                if (includeAuth(afterClassStudyRead)) {
                  return path.AUDITION_PUBLIC_COURSE;
                }
                if (includeAuth(batchScheduledCoursesRead)) {
                  return path.AUDITION_BATCH_SCHEDULED_COURSE;
                }
                if (includeAuth(realtimeCourseRead)) {
                  return path.AUDITION_REALTIME_COURSE;
                }
                if (includeAuth(courseTagRead)) {
                  return path.AUDITION_COURSETAG;
                }
                if (includeAuth(courseReviewRead)) {
                  return path.AUDITION_COURSE_REVIEW;
                }
                if (includeAuth(leaveRead)) {
                  return path.AUDITION_LEAVE;
                }
                return path.PERMISSION_ABNORMAL;
              })()}
            />
          ),
          index: true,
        },
      ];

      includeAuth(auditionLearningCoach) &&
        firstFolder.push({
          path: FirstRoute.learningCoach,
          element: <AuditionLearningCoach />,
          children: (() => {
            const secondFolder: RouteObject[] = [
              {
                element: <AuditionLearningCoachForm />,
                index: true,
              },
              {
                element: (
                  <Navigate
                    to={(() => {
                      return path.AUDITION_LEARNING_PLAN;
                    })()}
                  />
                ),
                index: true,
              },
            ];

            secondFolder.push({
              path: SecondRoute.learningPlan,
              children: [
                {
                  element: <AuditionLearningPlan />,
                  index: true,
                },
              ],
            });
            return secondFolder;
          })(),
        });

      includeAuth(auditionVipPass) &&
        firstFolder.push({
          path: FirstRoute.vipPass,
          element: <AuditionVipPass />,
        });

      includeAuth(auditionRead) &&
        firstFolder.push({
          path: FirstRoute.reservation,
          element: (
            <ReservationCourseProvider>
              <AuditionReservation />
            </ReservationCourseProvider>
          ),
        });

      includeAuth(auditionCourseRead) &&
        firstFolder.push({
          path: FirstRoute.trialcourse,
          element: <AuditionTrialCourse />,
          children: (() => {
            const secondFolder: RouteObject[] = [
              {
                element: (
                  <Navigate
                    to={(() => {
                      return path.AUDITION_TRIAL_COURSE_READ;
                    })()}
                  />
                ),
                index: true,
              },
            ];

            secondFolder.push({
              path: SecondRoute.read,
              children: [
                {
                  path: Route.id,
                  element: <AuditionTrialCourseItem />,
                },
              ],
            });
            return secondFolder;
          })(),
        });

      includeAuth(courseFormalAuditionRead) &&
        firstFolder.push({
          path: FirstRoute.course,
          element: <AuditionCourse />,
          children: (() => {
            const secondFolder: RouteObject[] = [
              {
                element: <AuditionCourseManagement />,
                index: true,
              },
              {
                element: (
                  <Navigate
                    to={(() => {
                      if (includeAuth(courseCreate)) {
                        return path.AUDITION_COURSE_CREATE;
                      }
                      if (includeAuth(courseEdit)) {
                        return path.AUDITION_COURSE_EDIT;
                      }
                      if (includeAuth(courseRead)) {
                        return path.AUDITION_COURSE_READ;
                      }
                      return path.LOGIN;
                    })()}
                  />
                ),
                index: true,
              },
            ];

            includeAuth(courseCreate) &&
              secondFolder.push({
                path: SecondRoute.create,
                element: <AuditionCourseItem />,
                index: true,
              });

            includeAuth(courseCreate) &&
              secondFolder.push({
                path: SecondRoute.copy,
                children: [
                  {
                    path: Route.id,
                    element: <AuditionCourseItem />,
                  },
                ],
              });

            includeAuth(courseRead) &&
              secondFolder.push({
                path: SecondRoute.read,
                children: [
                  {
                    path: Route.id,
                    element: <AuditionCourseDetail />,
                  },
                ],
              });
            includeAuth(courseEdit) &&
              secondFolder.push({
                path: SecondRoute.edit,
                children: [
                  {
                    path: Route.id,
                    element: <AuditionCourseItem />,
                  },
                ],
              });
            secondFolder.push({
              path: SecondRoute.feedback,
              children: [
                {
                  path: Route.id,
                  element: <AuditionCourseFeedback />,
                },
              ],
            });

            return secondFolder;
          })(),
        });

      includeAuth(afterClassStudyRead) &&
        firstFolder.push({
          path: FirstRoute.publicCourse,
          element: <AuditionPublicCourse />,
          children: (() => {
            const secondFolder: RouteObject[] = [
              {
                element: (
                  <Navigate
                    to={(() => {
                      if (includeAuth([Auth.AFTER_CLASS_STUDY_CREATE])) {
                        return path.AUDITION_PUBLIC_COURSE_CREATE;
                      }
                      if (includeAuth([Auth.AFTER_CLASS_STUDY_EDIT])) {
                        return path.AUDITION_PUBLIC_COURSE_EDIT;
                      }
                      if (includeAuth([Auth.AFTER_CLASS_STUDY_READ])) {
                        return path.AUDITION_PUBLIC_COURSE_READ;
                      }
                      return path.LOGIN;
                    })()}
                  />
                ),
                index: true,
              },
            ];

            includeAuth([Auth.AFTER_CLASS_STUDY_CREATE]) &&
              secondFolder.push({
                path: SecondRoute.create,
                element: <AuditionPublicCourseItem />,
                index: true,
              });
            includeAuth([Auth.AFTER_CLASS_STUDY_READ]) &&
              secondFolder.push({
                path: SecondRoute.read,
                element: <AuditionPublicCourseItem />,
                index: true,
              });
            includeAuth([Auth.AFTER_CLASS_STUDY_EDIT]) &&
              secondFolder.push({
                path: SecondRoute.edit,
                element: <AuditionPublicCourseItem />,
                index: true,
              });
            return secondFolder;
          })(),
        });

      includeAuth(batchScheduledCoursesRead) &&
        firstFolder.push({
          path: FirstRoute.batchscheduledcourse,
          element: <BatchScheduledCourse />,
          children: (() => {
            const secondFolder: RouteObject[] = [
              {
                element: (
                  <Navigate
                    to={(() => {
                      if (includeAuth([Auth.BATCH_SCHEDULED_COURSES_CREATE])) {
                        return path.AUDITION_BATCH_SCHEDULED_COURSE_CREATE;
                      }
                      return path.LOGIN;
                    })()}
                  />
                ),
                index: true,
              },
            ];

            includeAuth([Auth.BATCH_SCHEDULED_COURSES_CREATE]) &&
              secondFolder.push({
                path: SecondRoute.create,
                element: <BatchScheduledCourseForm />,
                index: true,
              });

            includeAuth([Auth.BATCH_SCHEDULED_COURSES_READ]) &&
              secondFolder.push({
                path: SecondRoute.read,
                children: [
                  {
                    path: Route.id,
                    element: <BatchScheduledCourseDetail />,
                  },
                ],
              });

            secondFolder.push({
              path: SecondRoute.copy,
              children: [
                {
                  path: Route.id,
                  element: <BatchScheduledCopyCourseForm />,
                },
              ],
            });

            return secondFolder;
          })(),
        });

      includeAuth(realtimeCourseRead) &&
        firstFolder.push({
          path: FirstRoute.realtimecourse,
          element: <AuditionRealtimeCourse />,
          children: (() => {
            const secondFolder: RouteObject[] = [
              {
                element: (
                  <Navigate
                    to={(() => {
                      if (includeAuth(realtimeCourseRead)) {
                        return path.AUDITION_REALTIME_COURSE_READ;
                      }
                      return path.LOGIN;
                    })()}
                  />
                ),
                index: true,
              },
            ];

            includeAuth(realtimeCourseRead) &&
              secondFolder.push({
                path: SecondRoute.read,
                children: [
                  {
                    path: Route.id,
                    element: <TutorialCenterCourseItem />,
                  },
                ],
              });

            return secondFolder;
          })(),
        });

      firstFolder.push({
        path: FirstRoute.omscourse,
        element: <OmsCourse />,
      });

      includeAuth(courseTagRead) &&
        firstFolder.push({
          path: FirstRoute.coursetag,
          element: <Coursetag />,
          children: (() => {
            const secondFolder = [
              {
                element: (
                  <Navigate
                    to={(() => {
                      if (includeAuth(courseTagRead)) {
                        return path.AUDITION_COURSETAG;
                      }
                      return path.LOGIN;
                    })()}
                  />
                ),
                index: true,
              },
            ];

            return secondFolder;
          })(),
        });

      firstFolder.push({
        path: FirstRoute.nowcourse,
        element: <NowCourse />,
        children: (() => {
          const secondFolder: RouteObject[] = [
            {
              element: <NowCourseManagement />,
              index: true,
            },
          ];

          includeAuth([
            Auth.NOW_COURSE_PRE_CLASS_READ,
            Auth.NOW_COURSE_FORMAL_READ,
          ]) &&
            secondFolder.push({
              path: SecondRoute.read,
              children: [
                {
                  path: Route.id,
                  element: <NowCourseDetail />,
                },
              ],
            });

          includeAuth([...nowCoursePreClassEdit, ...nowCourseFormalEdit]) &&
            secondFolder.push({
              path: SecondRoute.edit,
              children: [
                {
                  path: Route.id,
                  element: <NowCourseForm />,
                },
              ],
            });

          secondFolder.push({
            path: path.AUDITION_NOW_COURSE_FEEDBACK,
            children: [
              {
                path: Route.id,
                element: <NowCourseFeedback />,
              },
            ],
          });

          return secondFolder;
        })(),
      });

      includeAuth(courseReviewRead) &&
        firstFolder.push({
          path: FirstRoute.coursereview,
          element: <CourseReview />,
          children: (() => {
            const secondFolder: RouteObject[] = [
              {
                element: (
                  <Navigate
                    to={(() => {
                      return path.AUDITION_COURSE_REVIEW_READ;
                    })()}
                  />
                ),
                index: true,
              },
            ];

            secondFolder.push({
              path: SecondRoute.reviewdetail,
              children: [
                {
                  path: Route.id,
                  children: [
                    {
                      path: ':reviewId',
                      element: <ReviewForm />,
                    },
                  ],
                },
              ],
            });
            return secondFolder;
          })(),
        });

      includeAuth(leaveRead) &&
        firstFolder.push({
          path: FirstRoute.leave,
          element: <Leave />,
          children: (() => {
            const secondFolder: RouteObject[] = [
              {
                element: <Navigate to={path.AUDITION_LEAVE_READ} />,
                index: true,
              },
            ];

            secondFolder.push({
              path: SecondRoute.read,
              children: [
                {
                  element: <Navigate to={path.NOT_FOUND} />,
                  index: true,
                },
                {
                  path: Route.id,
                  element: <LeaveDetail />,
                },
              ],
            });

            return secondFolder;
          })(),
        });

      const courseClaimRoutes = generateCourseClaimRoutes(permissions);
      if (courseClaimRoutes) {
        firstFolder.push(courseClaimRoutes);
      }
      return firstFolder;
    })(),
  };
};

export { generateAuditionRoutes };
