import {
  EOrderRecordType,
  CONTRACT_EXTENSION_STATUS,
} from 'constants/order.constants';
import { ENowOrderStatus } from 'constants/order/now-order.constants';
// 訂單勾選設定
import { SEMESTER } from 'constants/textbook.constants';
import { t } from 'utils/i18n';
import {
  STRATEGY_TYPE,
  EPayment,
  EPaymentFlow,
} from '../electroniccommerce/setting.type';
import { TInvoiceStatus } from '../order/invoice';
import { ESource } from './plan.type';
import { ContractType } from './contract.type';

export enum EChooseType {
  ANY = 'any',
  SUGGESTION = 'suggestion',
  REQUIRED = 'required',
  CHOOSE_ONE = 'chooseOne',
}

export const ChooseTypeText = {
  [EChooseType.ANY]: () =>
    t('type.electroniccommerce.order.chooseTypeText.any', '任選（預設不勾選）'),
  [EChooseType.SUGGESTION]: () =>
    t(
      'type.electroniccommerce.order.chooseTypeText.suggestion',
      '建議勾選（預設為勾選）',
    ),
  [EChooseType.REQUIRED]: () =>
    t('type.electroniccommerce.order.chooseTypeText.required', '必選'),
  [EChooseType.CHOOSE_ONE]: () =>
    t('type.electroniccommerce.order.chooseTypeText.chooseOne', '多選一'),
};

export type TSelectOptions = {
  value: string;
  name: string;
  chooseType: EChooseType;
};

export type TProduct = {
  value: string; // 商品UID
  label: string; // 商品名稱
  count: number; // 數量
  price: number; // 價格
  description: string; // 描述
  promotions: string[]; // 選取之 促銷
  promotionOptions: TSelectOptions[]; // 包含之 促銷選項 (for勾選)
  paymentSettings?: { price: number; installment: number }[];
};

export type TPlan = {
  value: string; // 方案UID
  label: string; // 方案名稱
  products: TProduct[]; // 選取之 商品
  productOptions: TSelectOptions[]; // 包含之 商品選項 (for下拉選單)
  promotions: string[]; // 選取之 促銷
  promotionOptions: TSelectOptions[]; // 包含之 促銷選項 (for勾選)
};

export type TProductOptions = {
  label: string;
  value: string;
  price: number;
  description: string;
  duration: number;
};

export type TPlanOptions = {
  label: string;
  value: string;
};

export interface IPerformanceRatio {
  manager: {
    _id: string;
    staffNumber: string;
    name: string;
  };
  ratio: number;
}
export interface IOrderRatioDataResponse {
  id: string;
  orderId: string;
  performanceRatio: IPerformanceRatio[];
}

export enum orderState {
  paid = 'paid', // 已付款
  arrearage = 'arrearage', // 未付款
  audit = 'audit', // 審核中
  reject = 'reject', // 拒絕交易
  payment_order_cancel = 'payment-order-cancel', // 金流方訂單取消
  payment_trade_cancel = 'payment-trade-cancel', // 金流方交易取消
  cancel = 'cancel', // 已取消
  authorization_failed = 'authorization-failed', // 金流方交易失敗
  refund_applying = 'refund-applying', // 退費申請中
  refund_pending = 'refund-pending', // 待退款
  refunded = 'refunded', // 已退款
}
export enum EServiceExtensionRecordType {
  CONTRACT_EXTENSION_MODIFY = 'contract_extension_modify',
  CONTRACT_EXTENSION_TERMINATE = 'contract_extension_terminate',
  ORDER_EXTENSION_CREATED = 'order_extension_created',
}

export interface IOrdersRatio {
  id: string;
  orderId: string;
  performanceRatio: {
    manager: {
      _id: string;
      staffNumber: string;
      name: string;
      departments: {
        _id: string;
        parent: string;
        name: string;
        level: string;
      }[];
    };
    ratio: number;
  }[];
}

export interface IOrderMeParams {
  skip?: number;
  limit?: number;
  id?: string;
  status?: orderState;
  payment?: Exclude<EPayment, EPayment.noCashTrade>;
  installment?: number;
  crmNo?: string;
  customerName?: string;
  customerId?: string;
  startAt?: number;
  endAt?: number;
  dateType?: 'create' | 'pay';
}

export interface ITransferOrderParams {
  transferredCustomerId: string;
}

export interface IGetOrdersByCustomerParams {
  skip?: number;
  limit?: number;
}

interface IOrderSku {
  name: string;
  price: number;
  quantity: number;
  sku: string;
  type: string;
}
interface IPromotion {
  id: string;
  name: string;
  strategy: {
    discount: number;
    type: STRATEGY_TYPE;
    skus?: IOrderSku[];
  };
}

interface IStudents {
  name: string;
  education: string;
  grade: number;
}
interface IProduct {
  id: string;
  name: string;
  price: number;
  description: string;
  paymentSettings: {
    price: number;
    installment: number;
  }[];
  skus: {
    quantity: number;
    name: string;
    price: number;
    type: string;
  }[];
}
interface ICommodityProduct {
  product: IProduct;
  quantity: number;
  productPromotions: IPromotion[];
}
interface ITransferMoneyInfo {
  bankName: string;
  transferAccount: string;
  transferDate: number;
  description: string;
}
interface IAttachments {
  attachmentName: string;
  attachmentUrl: string;
}
interface IPlan {
  name: string;
  id: string;
}
export interface ICommodities {
  plan: IPlan;
  products: ICommodityProduct[];
  planPromotions: string[];
}
export interface IContractsGroup {
  name: string;
  path: string;
}
interface ICredit {
  count: number;
  skuId: string;
  expired: string;
  order: string;
  price: number;
  purchasedCount: number;
  remainPrice: number;
  usedCount: number;
}
interface IAddress {
  county: string;
  districts: string;
  address: string;
}
export interface ICustomerInfo {
  name: string;
  phone: string;
  email: string;
  isForeign: boolean;
  mailingAddress: IAddress;
  deliveryAddress: IAddress;
}
interface IDepartment {
  _id: string;
  level: string;
  name: string;
  parent: string;
}
export interface IManager {
  departments: IDepartment[];
  name: string;
  staffNumber: string;
  _id: string;
}
export interface IPresentCommodity {
  plan: {
    id: string;
    name: string;
  };
  products: ICommodityProduct[];
  planPromotions: IPromotion[];
}

export interface IOutsideManager {
  id: string;
  name: string;
  ratio: number;
}
interface ITempProductInfo {
  productName: string;
  amt: number;
  productInfo?: string;
}
interface IContract {
  contractName: string;
  contractUrl: string;
}
export interface IOnelinkParams {
  outsideManagers?: IOutsideManager[];
  organizationName: string;
  organizationOwnerMobile: string;
  organizationPhoneNumber?: string;
  InvoicesType: string;
  customMemo?: string;
  tempProductInfo: ITempProductInfo;
  contracts: IContract[];
  contractMemo?: string;
  contractType?: keyof typeof ContractType;
}

export interface IInvoiceRecord {
  staffId: string;
  status: TInvoiceStatus;
  time: number;
  reason: string;
  staffName: string;
}

export interface IInvoice {
  invoiceNumber: number;
  status: TInvoiceStatus;
  records: Array<IInvoiceRecord>;
  id: string;
  invoiceTime: number;
  phases: number;
  /** 是否為補繳款所開立的發票 */
  suppl: boolean;
}
export interface IOrder {
  contractExtension: string[];
  amt: number;
  attachments?: IAttachments[];
  authorizationCode: string;
  commodities: ICommodities[];
  contract: string;
  contractsGroup: IContractsGroup[];
  createdAt: number;
  creditList: ICredit[];
  crmNo: string;
  customerId: string;
  customerInfo: ICustomerInfo;
  expired: number;
  formerManager: string;
  id: string;
  installment: number;
  invoices: IInvoice[];
  isOnePay?: boolean;
  isRenewDiscount: boolean;
  isSendContract: boolean;
  managers: IManager[];
  mgmUrl: string;
  payAt: number;
  payment: EPayment;
  paymentMethod: EPaymentFlow;
  presentCommodities: IPresentCommodity[];
  productExpired: number;
  renewCount: number;
  status: orderState;
  students: IStudents[];
  transferMoneyInfo?: ITransferMoneyInfo;
  /** 發票 - 公司統編 */
  uniformNumbers: string;
  /** 發票 - 公司抬頭 */
  companyLetterhead: string;
  /** 發票 - 公司地址 */
  companyAddress: string;
  /** 發票 - 發票品名 */
  invoiceItemName: string;
  /** 手機條碼載具 */
  mobileCarrier: string;
  /** 自然人憑證條碼載具 */
  citizenDigitalCertificateCarrier: string;
  _id: string;
  refund: string[];
  refundAmt: number;
  refundAt: number;
  refundEditor: string;
  from?: string;
  source: ESource;
  onelinkParams: IOnelinkParams;
  currentContractExpiredAt?: Date;

  semester?: SEMESTER.FIRST | SEMESTER.SECOND;
  schoolYear?: string;
}
export interface IGetOrdersByCustomerResponse {
  orders: IOrder[];
  total: number;
}
export interface IGetOrderRecordsResponse {
  type: EOrderRecordType;
  orderId: string;
  editor: {
    staffNumber: string;
    name: string;
  };
  createdAt: string;
  updatedAt: string;
  id: string;
}

export interface IOrderPeriodParam {
  startAt: number;
  endAt: number;
  isRefund?: boolean;
  source?: ESource;
  sources?: ESource[];
}

export interface IOrdersReportResponse {
  orders: IOrder[];
  total: number;
}

export interface IOrderMeResponse {
  orders: IOrder[];
  total: number;
}

export interface ICreateOrderRefundParams {
  order: string; // 訂單編號
  signedContractFiles: File[];
  otherAttachmentsFiles: File[];
  status: 'active';
  paidInstallments?: number;
  paidAmount?: number;
  dueAmount?: number;
  missedPaymentAmount?: number;
  refundAmount?: number;
}

export interface IUpdateOrderRefundParams {
  amount: number;
  executionDate: string;
}

export interface IRefundContract {
  originalname: string;
  filename: string;
  path: string;
  size: number;
  createdAt: string;
}

export interface IPatchOrderRefundParams {
  amount?: number;
  executionDate?: string;
  signedContractFiles?: File[];
  otherAttachmentsFiles?: File[];
  status?: 'active' | 'cancelled';
  updatedSignedContracts?: IRefundContract[];
  updatedOtherAttachments?: IRefundContract[];
  paidInstallments: number | string;
  paidAmount: number | string;
  dueAmount: number | string;
  missedPaymentAmount: number | string;
  refundAmount: number | string;
}

export interface IOrderRefundResponse {
  status: 'active' | 'cancelled';
  order: string;
  signedContract: IRefundContract[];
  otherAttachments: IRefundContract[];
  amount: number;
  executionDate: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  paidInstallments: number;
  paidAmount: number;
  dueAmount: number;
  missedPaymentAmount?: number;
  refundAmount?: number;
}

export interface IGetOrderRefundListParams {
  skip: number;
  limit: number;
  id: string; // 退費單 id
  order: string; // 訂單編號
  ids: string[]; // 退費單 ids
  status: ('active' | 'cancelled')[]; // 退費單狀態
}

export interface ICreateContractExtensionParams {
  order: string; // 訂單編號
  contractFiles: File[];
  expired: Date;
}

export interface IContractItem {
  originalname: string;
  filename: string;
  path: string;
  size: number;
  createdAt: string;
  expired: string;
}

export interface IContractExtensionResponse {
  order: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  __v: number;
  contract: IContractItem[];
}

export interface IGetContractExtensionListResponse {
  order: string;
  contract: IContractItem[];
  createdAt: string;
  expired: string;
  __v: number;
  id: string;
}

export interface IUpdateContractExtensionPayload {
  payAt?: string;
  contractExpiredAt?: string;
  uniformNumbers?: string;
  invoice?: string;
  companyLetterhead?: string;
  companyAddress?: string;
  status?: orderState;
  managers?: string[];
}

export interface IAddContractExtensionPayload {
  contractFiles: File[];
  expired: Date;
  updatedContracts: IContractItem[];
}

export interface IUpdateLearningBarOrderByIdPayload {
  managers: string[];
  onelinkParams: IOnelinkParams;
  customerInfo: ICustomerInfo;
  crmNo: string;
  uniformNumbers?: string;
  companyLetterhead?: string;
  companyAddress?: string;
  formerManager?: string;
  expired: number;
  productExpired: number;
  amt: number;
}

export interface IUpdateLearningBarOrderByIdResponse {
  id: string;
  managers: IManager[];
  crmNo: string;
  customerId: string;
  customerInfo: ICustomerInfo;
  uniformNumbers: string;
  companyLetterhead: string;
  companyAddress: string;
  isRenewDiscount: boolean;
  students: IStudents[];
  payment: string;
  installment: number;
  mgmUrl: string;
  commodities: ICommodities[];
  expired: number;
  productExpired: number;
  amt: number;
  status: string;
  contractsGroup: IContractsGroup[];
  invoice: string;
  transferMoneyInfo: ITransferMoneyInfo;
  authorizationCode: string;
  renewCount: number;
  formerManager: string;
  attachments: IAttachments[];
  isSendContract: IAttachments[];
  refundAmt: number;
  refundAt: number;
  refundEditor: string;
  refund: string;
  source: string;
}

export interface IUpdateTutorialOrderByIdPayload {
  managers: string[];
  onelinkParams: IOnelinkParams;
  customerInfo: ICustomerInfo;
  uniformNumbers?: string;
  companyLetterhead?: string;
  companyAddress?: string;
  formerManager?: string;
  expired: number;
  productExpired: number;
  amt: number;
}

export interface IUpdateTutorialOrderByIdResponse {
  id: string;
  managers: IManager[];
  customerInfo: ICustomerInfo;
  uniformNumbers: string;
  companyLetterhead: string;
  companyAddress: string;
  isRenewDiscount: boolean;
  students: IStudents[];
  payment: string;
  installment: number;
  mgmUrl: string;
  commodities: ICommodities[];
  expired: number;
  productExpired: number;
  amt: number;
  status: string;
  contractsGroup: IContractsGroup[];
  invoice: string;
  transferMoneyInfo: ITransferMoneyInfo;
  authorizationCode: string;
  renewCount: number;
  formerManager: string;
  attachments: IAttachments[];
  isSendContract: IAttachments[];
  refundAmt: number;
  refundAt: number;
  refundEditor: string;
  refund: string;
  source: string;
}

export interface IUpdateSchoolOrderByIdPayload {
  managers: string[];
  onelinkParams: IOnelinkParams;
  customerInfo: ICustomerInfo;
  uniformNumbers?: string;
  companyLetterhead?: string;
  companyAddress?: string;
  formerManager?: string;
  expired: number;
  productExpired: number;
  amt: number;
}

export interface IUpdateSchoolOrderByIdResponse {
  id: string;
  managers: IManager[];
  customerInfo: ICustomerInfo;
  uniformNumbers: string;
  companyLetterhead: string;
  companyAddress: string;
  isRenewDiscount: boolean;
  students: IStudents[];
  payment: string;
  installment: number;
  mgmUrl: string;
  commodities: ICommodities[];
  expired: number;
  productExpired: number;
  amt: number;
  status: string;
  contractsGroup: IContractsGroup[];
  invoice: string;
  transferMoneyInfo: ITransferMoneyInfo;
  authorizationCode: string;
  renewCount: number;
  formerManager: string;
  attachments: IAttachments[];
  isSendContract: IAttachments[];
  refundAmt: number;
  refundAt: number;
  refundEditor: string;
  refund: string;
  source: string;
}

export interface IGetContractsExtensionOrdersParams {
  skip: number;
  limit: number;
  startAt?: string;
  endAt?: string;
  status?: CONTRACT_EXTENSION_STATUS[];
  contractExtensionId?: string;
  order?: string;
}

export interface IContractsExtensionOrder {
  id: string;
  contractExtensionId: string;
  order: IOrder;
  status: CONTRACT_EXTENSION_STATUS;
  expired: ISOString;
  month: string;
  payAt: ISOString;
  contractExpiredAt: ISOString;
  payAmount: string;
  contract: string;
  payment: EPayment;
  paymentMethod: EPaymentFlow;
  installment: string; // 1
  managers: IManager[];
  commodities: [
    {
      plan: string;
      products: [
        {
          product: string;
          quantity: number;
          productPromotions: string[];
        },
      ];
      planPromotions: string[];
    },
  ];
  uniformNumbers: string; // 統一編號
  invoice: string; // 發票號碼
  createdAt: ISOString;
}
export interface IGetContractsExtensionOrdersResponse {
  total: number;
  contractsExtension: IContractsExtensionOrder[];
}
// 異動紀錄
export interface IServiceExtensionRecord {
  id: string;
  type: EServiceExtensionRecordType;
  order: string;
  contractExtensionId?: string;
  expiredDate: Date;
  attachments: {
    name: string;
    url: string;
  }[];
  reason: string;
  editor: {
    staffNumber: string;
    name: string;
  };
  createdAt?: Date;
  updatedAt?: Date;
}
export interface IGetExtensionRecordParams {
  skip?: number;
  limit?: number;
}
export interface IGetExtensionRecordResponse {
  total: number;
  serviceExtensionRecords: IServiceExtensionRecord[];
}

// 手動展延 Params
export interface ICreateManualExtensionParams {
  expiredDate: ISOString;
  reason: string;
  attachments: {
    name: string;
    url: string;
  }[];
}
// 手動展延 Response
export interface ICreateManualExtensionResponse {
  orders: IOrder[];
}

export type NowOrder = {
  amount: string;
  createdAt: string;
  expiredAt: string;
  id: string;
  installment: string;
  orderNumber: string;
  payAt: string;
  payment: EPayment.payuni;
  productName: string;
  status: ENowOrderStatus;
  userId: string;
  userName: string;
};

export type ErrorCollector = {
  [key: string]: {
    text: string;
    isError: boolean;
  };
};

export type UniformInformation = Pick<
  IOrder,
  'uniformNumbers' | 'companyLetterhead' | 'companyAddress' | 'invoiceItemName'
>;
