export const ERROR_CODES = {
  3400: '錯誤請求',
  3401: '操作未授權',
  3403: '禁止操作',
  3404: '資料讀取失敗',
  3410: '創建失敗',
  3411: '刪除失敗',
  3412: '缺少必要參數',
  3413: '更新失敗',
  3414: '創建帳號失敗',
  3415: '批次創建帳號失敗',
  3416: '命名已存在',
  3417: '雲端空間已滿',
  3418: '此網域已存在',
  3420: '權限不足',
  3500: '網路異常或未知錯誤',
  6001: '帳號已存在',
  6002: 'Email 已被使用',
  6010: '帳號不可使用特殊符號',
  6011: '帳號需以英文字母開頭',
  6012: '帳號限定 6~30 字元',
  6401: '帳號或密碼輸入錯誤',
  7001: '此學生尚未交卷',
  7002: '錯題派發失敗',
  7003: '此學生已無剩餘錯題',
};

export enum Role {
  admin = 0,
  organization = 1,
  subOrganization = 2,
  teacher = 3,
  student = 4,
  parent = 5,
}

export enum Grade {
  G01 = 'G01', // 小一
  G02 = 'G02', // 小二
  G03 = 'G03', // 小三
  G04 = 'G04', // 小四
  G05 = 'G05', // 小五
  G06 = 'G06', // 小六
  G07 = 'G07', // 國一
  G08 = 'G08', // 國二
  G09 = 'G09', // 國三
  G10 = 'G10', // 高一
  G11 = 'G11', // 高二
  G12 = 'G12', // 高三
}

export enum Edu {
  E = 'E', // 國小
  J = 'J', // 國中
  H = 'H', // 高中
}

export enum Subject {
  CH = 'CH', // 國語
  PC = 'PC', // 國文
  CN = 'CN', // 華語
  EN = 'EN', // 英語
  EW = 'EW', // 英文
  MA = 'MA', // 數學
  NA = 'NA', // 自然
  BI = 'BI', // 生物
  PH = 'PH', // 物理
  PY = 'PY', // 理化
  EA = 'EA', // 地球科學
  AH = 'AH', // 藝術
  CE = 'CE', // 化學
  SO = 'SO', // 社會
  GE = 'GE', // 地理
  HI = 'HI', // 歷史
  CT = 'CT', // 公民
  CS = 'CS', // 公民與社會
  LI = 'LI', // 生活
  PE = 'PE', // 健康與體育
  CO = 'CO', // 綜合活動
  TC = 'TC', // 科技
  CW = 'CW', // 國寫
  CU = 'CU', // 全科
}

export enum Version {
  A = 'A', // 萬通
  N = 'N', // 南一
  K = 'K', // 康軒
  H = 'H', // 翰林
  L = 'L', // 龍騰
  S = 'S', // 三民
  B = 'B', // 部編
  CW = 'CW', // 全華
  G = 'G', // 綜合
  E = 'E', // 何嘉仁
  KX = 'KX', // 康熹
  T = 'T', // 泰宇
  OT = 'OT', // 其他
}

export enum Difficult {
  Begin = '易',
  Basic = '偏易',
  Intermediate = '中',
  Advanced = '偏難',
  Expert = '難',
}

export const PERMISSIONS_WITH_EDU_SUBJECT: { [serialCode: string]: string[] } =
  {
    'F-02': ['F-02-05', 'F-02-06', 'F-02-07'],
    'F-08': ['F-08-01', 'F-08-02', 'F-08-03'],
  };

export enum OrganizationCategory {
  tutorial = 'tutorial',
  learningBar = 'learningBar',
  school = 'school',
  partner = 'partner',
  other = 'other',
}
export enum OrganizationCategoryCode {
  tutorial = 0, // 補習班
  learningBar = 1, // 學霸
  school = 2, // 學校
  partner = 3, // 合作夥伴
  other = 4, // 其他
}
export const ORGANIZATION_CATEGORY_MAP = {
  [OrganizationCategoryCode.tutorial]: OrganizationCategory.tutorial,
  [OrganizationCategoryCode.learningBar]: OrganizationCategory.learningBar,
  [OrganizationCategoryCode.school]: OrganizationCategory.school,
  [OrganizationCategoryCode.partner]: OrganizationCategory.partner,
  [OrganizationCategoryCode.other]: OrganizationCategory.other,
};
export const ORGANIZATION_CATEGORY_LABEL_MAP = {
  [OrganizationCategoryCode.tutorial]: '補習班',
  [OrganizationCategoryCode.learningBar]: '學霸',
  [OrganizationCategoryCode.school]: '學校',
  [OrganizationCategoryCode.partner]: '合作夥伴',
  [OrganizationCategoryCode.other]: '其他',
};
