import { AxiosInstance } from 'axios';
import {
  GetOrganizationByIdPayload,
  GetOrganizationByIdResponse,
} from 'api/link-plus/types';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  getOrganizationByUid(
    payload: GetOrganizationByIdPayload,
  ): GetOrganizationByIdResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent('organization/get/info/byUid')}`,
      payload,
    );
  },
});
