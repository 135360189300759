import { Dispatch, SetStateAction } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IUserData } from 'services/LoginService';
import { HEADER, NAVBAR } from '../../config';
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: prop => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

interface IHorizontalLayout {
  loginUser: IUserData | null;
  setLoginUser: Dispatch<SetStateAction<IUserData | null>>;
  collapseClick: boolean;
  isCollapse?: boolean;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const HorizontalLayout = ({
  loginUser,
  setLoginUser,
  collapseClick,
  isCollapse,
  open,
  setOpen,
}: IHorizontalLayout) => (
  <Box
    sx={{
      display: 'flex',
      minHeight: 1,
    }}
  >
    <DashboardHeader
      isCollapse={isCollapse}
      onOpenSidebar={() => setOpen(true)}
      loginUser={loginUser}
      setLoginUser={setLoginUser}
    />
    <NavbarVertical
      isOpenSidebar={open}
      onCloseSidebar={() => setOpen(false)}
    />
    <MainStyle collapseClick={collapseClick}>
      <Outlet context={{ loginUser, setLoginUser }} />
    </MainStyle>
  </Box>
);
