import { AxiosInstance } from 'axios';
import {
  CreateOrganizationAdminPayload,
  CreateOrganizationAdminResponse,
  DeleteUserPayload,
  DeleteUserResponse,
  LoginPayload,
  LoginResponse,
  MeResponse,
} from 'api/link-plus/types';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  login(payload: LoginPayload): LoginResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent('user/login')}`,
      payload,
    );
  },
  me(): MeResponse {
    return axios.post(`${apiPrefix}/${encodeURIComponent('user/get/info/me')}`);
  },
  createOrganizationAdmin(
    payload: CreateOrganizationAdminPayload,
  ): CreateOrganizationAdminResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent(
        'user/create/role/organizationAdmin',
      )}`,
      payload,
    );
  },
  bindOrganizationAdminFromOneClub(
    payload: CreateOrganizationAdminPayload,
  ): CreateOrganizationAdminResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent(
        'user/bind/oneClub/user/forOrganization',
      )}`,
      payload,
    );
  },
  deleteByUid(payload: DeleteUserPayload): DeleteUserResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent('user/delete/byUid')}`,
      payload,
    );
  },
});
