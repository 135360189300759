import { AxiosInstance } from 'axios';
import { TApiResponse, TPagination } from '@type/common';
import { DrawdownRecordResponse } from './types';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /** 取得動撥上傳紀錄 */
  getDrawdownRecord(data: TPagination): TApiResponse<DrawdownRecordResponse> {
    return axios.get(`${apiPrefix}/drawdown-records`, { params: data });
  },
});
