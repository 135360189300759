import { Mui } from '@onedesign/ui';
import { Icon, SvgName } from 'components/Icon';
import path from 'routes/path';
import { navigateTo } from 'utils/common';

const { Badge, IconButton, useTheme } = Mui;

export const TicketChannelPopover = () => {
  const theme = useTheme();

  const handleOpen = () => {
    navigateTo(`${path.CUSTOMER_TICKET_MY_CASE}`);
  };

  return (
    <IconButton
      onClick={handleOpen}
      sx={{
        mr: '8px',
      }}
    >
      <Badge color="error">
        <Icon
          name={SvgName.TicketChannel}
          sx={{
            background: theme.palette.grey[600],
            cursor: 'pointer',
            width: '27px',
            height: '27px',
          }}
        />
      </Badge>
    </IconButton>
  );
};
