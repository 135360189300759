import { organizationManagementAuth } from 'constants/auth/auth';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { IUserData } from 'services/LoginService';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import {
  Auth,
  FirstRoute,
  Route,
  organizationPlanRead,
  organizationRead,
} from '../path';
import { Loadable } from '../utils';

const OrganizationManagement = Loadable(
  lazy(() => import('pages/organization-management/index')),
);

const OrganizationList = Loadable(
  lazy(
    () => import('pages/organization-management/organizations/Organizations'),
  ),
);

const OrganizationDetail = Loadable(
  lazy(
    () =>
      import(
        'pages/organization-management/organizations/OrganizationDetail/OrganizationDetail'
      ),
  ),
);

const PlansList = Loadable(
  lazy(() => import('pages/organization-management/plans/Plans')),
);

const PlanDetail = Loadable(
  lazy(
    () => import('pages/organization-management/plans/PlanDetail/PlanDetail'),
  ),
);

const generateOrganizationManagementRoutes = (loginUser: IUserData | null) => {
  const permissions = (loginUser?.permissions || []) as Auth[];
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!loginUser || !includeAuth(organizationManagementAuth)) {
    return null;
  }

  return {
    path: Route.organizationmanagement,
    element: <OrganizationManagement />,
    children: (() => {
      const firstFolder: RouteObject[] = [];

      if (includeAuth(organizationRead)) {
        firstFolder.push({
          path: FirstRoute.organizations,
          children: (() => {
            const secondFolder: RouteObject[] = [];
            secondFolder.push({
              children: [
                {
                  element: <OrganizationList />,
                  index: true,
                },
                {
                  element: <OrganizationDetail />,
                  path: Route.id,
                },
              ],
            });

            return secondFolder;
          })(),
        });
      }

      if (includeAuth(organizationPlanRead)) {
        firstFolder.push({
          path: FirstRoute.plans,
          children: (() => {
            const secondFolder: RouteObject[] = [];
            secondFolder.push({
              children: [
                {
                  element: <PlansList />,
                  index: true,
                },
                {
                  element: <PlanDetail />,
                  path: Route.id,
                },
              ],
            });

            return secondFolder;
          })(),
        });
      }
      return firstFolder;
    })(),
  };
};

export { generateOrganizationManagementRoutes };
