import { Dispatch, SetStateAction } from 'react';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { deleteCookie, getCookie, setCookie } from 'packages/utils/cookie';
import { KeyCookie } from 'plugins/cookie/cookie';
import { enqueueSnackbar, VariantType, OptionsWithExtraProps } from 'notistack';
import { TOKEN_DOMAIN } from './constants';

export const getToken = (): string | null => {
  const cookie = getCookie(KeyCookie.oneClassClient);
  if (cookie) {
    try {
      return JSON.parse(cookie).jwt;
    } catch (error) {
      return null;
    }
  }
  return null;
};

export const setToken = (token: string) =>
  setCookie(
    KeyCookie.oneClassClient,
    JSON.stringify({ code: 'SUCCESS', jwt: token }),
    undefined,
    TOKEN_DOMAIN,
  );

export const deleteToken = () =>
  deleteCookie(KeyCookie.oneClassClient, TOKEN_DOMAIN);

export const showSnackbar = (
  message: string,
  variant: VariantType = 'success',
  props: OptionsWithExtraProps<'default'> = {},
) => {
  enqueueSnackbar(message, {
    anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
    variant,
    ...props,
  });
};

export const updateObjectState =
  <State>(setState: Dispatch<SetStateAction<State>>) =>
  <Key extends keyof State>(key: Key, value: State[Key]) =>
    setState(prev => ({ ...prev, [key]: value }));

export const getFullName = ({
  firstName,
  lastName,
}: Required<{ firstName: string; lastName: string }>) => lastName + firstName;

export const getUids = (arr: Required<{ uid: string }>[]) =>
  arr.map(({ uid }) => uid);

export const translateCountryName = (
  countryCode: string,
  language = 'zh-TW',
) => {
  try {
    const intl = new Intl.DisplayNames(language, { type: 'region' });
    return intl.of(countryCode);
  } catch (error) {
    return countryCode;
  }
};

/**
 * 將電話號碼轉為正確的格式
 */
export const formatPhoneWithCountryCode = (fullPhone: string) => {
  const phoneUtil = new PhoneNumberUtil();
  try {
    return phoneUtil.format(phoneUtil.parse(fullPhone), PhoneNumberFormat.E164);
  } catch (error) {
    return '';
  }
};

export const getCountryCodeAndNumber = (fullNumber: string) => {
  const phoneUtil = new PhoneNumberUtil();
  try {
    const numberObject = phoneUtil.parseAndKeepRawInput(fullNumber);
    return {
      countryCode: String(numberObject.getCountryCode()),
      nationalNumber: String(numberObject.getNationalNumber()),
    };
  } catch (error) {
    return {
      countryCode: '',
      nationalNumber: '',
    };
  }
};

export const fileSizeFormat = (bytes: number) => {
  const integer = Math.trunc(bytes);
  // eslint-disable-next-line no-restricted-syntax
  for (const n of [
    { number: 1000000000000, unit: 'TB' },
    { number: 1000000000, unit: 'GB' },
    { number: 1000000, unit: 'MB' },
    { number: 1000, unit: 'KB' },
  ]) {
    if (integer >= n.number) {
      return {
        size: Number((integer / n.number).toFixed(2)),
        unit: n.unit,
      };
    }
  }
  return {
    size: Number(integer.toFixed(2)),
    unit: 'B',
  };
};

export const createErrorParser = (message: string) => {
  try {
    return (JSON.parse(message)?.errorCodes[0] as number) || null;
  } catch (error) {
    return null;
  }
};
