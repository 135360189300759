export function getCookie(name: string): string | null {
  if (typeof window === 'undefined') return null;
  const cookieStr = document.cookie;
  const cookies = cookieStr.split(';');

  const result = cookies
    .find(item => {
      const cookie = item.trim();
      const chars = cookie.split('=');
      return chars[0] === name;
    })
    ?.trim()
    .substring(name.length + 1);

  return result ? decodeURIComponent(result) : null;
}

export const deleteCookie = (
  name: string,
  domainName = window.location.hostname,
) => {
  const domain = `domain=${domainName}`;
  document.cookie =
    window.location.hostname === 'localhost'
      ? `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
      : `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;${domain};`;
};

export function setCookie(
  name: string,
  value: string,
  exdays = 3,
  domainName = window.location.hostname,
) {
  const date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  const domain =
    window.location.hostname === 'localhost' ? '' : `domain=${domainName}`;
  document.cookie = `${name}=${value};${expires};path=/;${domain}`;
}
