import path from 'routes/path';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import cookies, { KeyCookie } from 'plugins/cookie/cookie';
import { URL } from './axios';

const instance = axios.create();

const onRequest = (config: AxiosRequestConfig) => {
  const URLS_REQUIRE_BEARER: Readonly<string[]> = [URL.ONE_ANSWER];

  const accessToken = cookies.get(KeyCookie.oneClassMMSClient)?.accessToken;

  if (config.headers && accessToken) {
    config.headers.Authorization = accessToken;
    if (URLS_REQUIRE_BEARER.some(url => config.url?.includes(url))) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }
  if (
    (URL.BITRIX && config.url?.includes(URL.BITRIX)) ||
    (URL.BITRIX_HOOK && config.url?.includes(URL.BITRIX_HOOK)) ||
    (URL.LIVE_CLASS && config.url?.includes(URL.LIVE_CLASS)) ||
    config.url?.includes('notify/subscribeTopic') ||
    (URL.ONEBOARD && config.url?.includes(URL.ONEBOARD)) ||
    (URL.GOOGLE_SHEET && config.url?.includes(URL.GOOGLE_SHEET)) ||
    (URL.FUN_LEARNING && config.url?.includes(URL.FUN_LEARNING))
  ) {
    if (config?.headers?.Authorization) {
      delete config.headers.Authorization;
    }
  }
  if (
    config.headers &&
    config.url?.includes('organizations/sessions-watching-records')
  ) {
    config.headers.Authorization = accessToken;
  }

  // remove empty params, params may be array, null or undefined
  if (config.params) {
    const _params = { ...config.params };
    Object.keys(_params).forEach(key => {
      if (
        _params[key] === null ||
        _params[key] === undefined ||
        (Array.isArray(_params[key]) &&
          _params[key].filter(
            (el: any) => el !== null && el !== undefined && el !== '',
          ).length === 0)
      ) {
        delete _params[key];
      }
    });
    config.params = _params;
  }

  return config;
};

const onResponse = (response: AxiosResponse) => {
  const { status, data } = response;

  if (status >= 500 || data?.error?.errorCode === 500) {
    return window.location.replace(path.SERVER_ERROR);
  }
  if (status >= 200 && status <= 299) {
    return response.data;
  }

  return response;
};

const onError = (error: AxiosError) => {
  const status = error?.response?.status || '500';

  return { status: `${status} - failure`, error };
};

instance.interceptors.request.use(onRequest);
instance.interceptors.response.use(onResponse, onError);

export default instance;
