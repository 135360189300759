import { TApiResponse } from '@type/common';
import { AxiosInstance } from 'axios';
import { CourseReviewCountResponseDTO } from './course-review.type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /**
   * 課程審核列表需審核數量
   */
  getCourseReviewCount(): TApiResponse<CourseReviewCountResponseDTO> {
    return axios.get(
      `${apiPrefix}/backend/course-review/review-list/department-count`,
    );
  },
});
