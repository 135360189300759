import { Mui } from '@onedesign/ui';
import { Icon, SvgName } from 'components/Icon';
import { AuthGuard } from 'components/authGuard';
import path, { Auth } from 'routes/path';
import { navigateTo } from 'utils/common';

const { Badge, IconButton, useTheme } = Mui;

export const LineNotifyPopover = () => {
  const theme = useTheme();

  const handleOpen = () => navigateTo(path.LINE_CHAT_MANAGEMENT);

  return (
    <AuthGuard allMatch={true} allowedPermissions={[Auth.LINE_CHAT_READ]}>
      <IconButton
        onClick={handleOpen}
        sx={{
          mr: '8px',
        }}
      >
        <Badge color="error">
          <Icon
            name={SvgName.Line}
            sx={{
              background: theme.palette.grey[600],
              cursor: 'pointer',
              width: '27px',
              height: '27px',
            }}
          />
        </Badge>
      </IconButton>
    </AuthGuard>
  );
};
