import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

export const DisplayLayout = () => {
  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Outlet />
    </Box>
  );
};
