import { useState } from 'react';
import { Mui } from '@onedesign/ui';
import { Icon, SvgName } from 'components/Icon';
import path, { Auth } from 'routes/path';
import { useLoginService } from 'services/LoginService';
import { navigateTo } from 'utils/common';

const { Badge, Popover, IconButton, useTheme, List, ListItem, ListItemButton } =
  Mui;

export const ChannelNotifyPopover = () => {
  const { loginUser } = useLoginService();
  const permissions = loginUser?.permissions || [];
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);

  const includeAuth = (auth: string[]) =>
    auth.some((item: any) => permissions.includes(item));

  // 有教師聊天權限or家長聊天權限才顯示
  const hasChannelPermission = includeAuth([
    Auth.TEACHER_TALK,
    Auth.PARENT_MESSAGE_EDIT,
  ]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToTeacher = () => navigateTo(`${path.SENDBIRD_TEACHER}`);
  const navigateToCustomer = () => navigateTo(`${path.SENDBIRD_CUSTOMER}`);

  if (!hasChannelPermission) return null;

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          mr: '8px',
        }}
      >
        <Badge color="error">
          <Icon
            name={SvgName.Dialog}
            sx={{
              background: theme.palette.grey[600],
              cursor: 'pointer',
              width: '27px',
              height: '27px',
            }}
          />
        </Badge>
      </IconButton>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          <ListItem sx={{ p: 0 }}>
            <ListItemButton onClick={navigateToTeacher}>教師</ListItemButton>
          </ListItem>
          <ListItem sx={{ p: 0 }}>
            <ListItemButton onClick={navigateToCustomer}>家長</ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};
