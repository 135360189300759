import { useEffect } from 'react';
import { useLoginService } from 'services/LoginService';
import { LoadingScreen } from '@onedesign/ui';
import Router from 'routes/index';

const Root = () => {
  const { isLoading, handleLoginPermission } = useLoginService();

  useEffect(() => {
    handleLoginPermission();
  }, []);

  return !isLoading ? <Router /> : <LoadingScreen />;
};

export default Root;
