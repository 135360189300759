import { Dispatch, SetStateAction } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { IUserData } from 'services/LoginService';
import { HEADER } from '../../config';
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';

interface IVerticalLayout {
  loginUser: IUserData | null;
  setLoginUser: Dispatch<SetStateAction<IUserData | null>>;
  isDesktop?: boolean;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const VerticalLayout = ({
  loginUser,
  setLoginUser,
  isDesktop,
  open,
  setOpen,
}: IVerticalLayout) => (
  <>
    <DashboardHeader
      onOpenSidebar={() => setOpen(true)}
      verticalLayout
      loginUser={loginUser}
      setLoginUser={setLoginUser}
    />
    {isDesktop ? (
      <NavbarHorizontal />
    ) : (
      <NavbarVertical
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
    )}
    <Box
      component="main"
      sx={{
        px: { lg: 2 },
        pt: {
          xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
          lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
        },
        pb: {
          xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
          lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
        },
      }}
    >
      <Outlet context={{ loginUser, setLoginUser }} />
    </Box>
  </>
);
