import { lazy } from 'react';
import { Route } from '../path';
import { Loadable } from '../utils';

const LineChatManagement = Loadable(
  lazy(() => import('pages/line-chat-management')),
);

const generateLineChatManagementRoutes = () => {
  return {
    path: Route.lineChatManagement,
    element: <LineChatManagement />,
  };
};

export { generateLineChatManagementRoutes };
