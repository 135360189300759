import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { remoteConfig } from 'plugins/firebase/firebase';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  ERemoteConfigParameter,
  FEATURE_DISABLEMENT_LIST,
} from './remoteConfigService.constants';
import { IRemoteConfigContext, TParameter } from './remoteConfigService.type';

export const RemoteConfigContext = createContext<IRemoteConfigContext>({
  parameter: {} as TParameter,
});

const remoteConfigParamKeys = Object.keys(ERemoteConfigParameter);

const initialRemoteConfigParam = () => {
  return remoteConfigParamKeys.reduce<TParameter>((acc, key) => {
    const value =
      import.meta.env.VITE_ENV === 'dev'
        ? true
        : !FEATURE_DISABLEMENT_LIST.includes(key as ERemoteConfigParameter);
    return {
      ...acc,
      [key]: value,
    };
  }, {} as TParameter);
};

export const RemoteConfigProvider = ({ children }: { children: ReactNode }) => {
  const [parameter, setParameter] = useState<TParameter>(
    initialRemoteConfigParam(),
  );

  /**
   * 取得 remote config 的參數
   */
  const getParameters = () => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        const params = remoteConfigParamKeys.reduce<TParameter>((prev, key) => {
          const param = getValue(remoteConfig, key).asBoolean();
          const value = param;
          return {
            ...prev,
            [key]: value,
          };
        }, {} as TParameter);
        setParameter(params);
      })
      // eslint-disable-next-line no-console
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getParameters();
  }, []);

  return (
    <RemoteConfigContext.Provider value={{ parameter }}>
      {children}
    </RemoteConfigContext.Provider>
  );
};

export const useRemoteConfigService = () => useContext(RemoteConfigContext);
