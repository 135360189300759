import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { IUserData } from 'services/LoginService';
import path, {
  Auth,
  FirstRoute,
  Route,
  SecondRoute,
  historyTicketsExport,
  historyTicketsRead,
  parentTicketsNew,
  parentMessageRead,
  parentTicketsHistoryRead,
  ticketsEdit,
  ticketsRead,
} from '../path';
import { Loadable } from '../utils';

const CustomerTicket = Loadable(
  lazy(() => import('pages/customerticket/index')),
);
const InstantCase = Loadable(
  lazy(() => import('pages/customerticket/instantcase/InstantCase')),
);
const MyCase = Loadable(
  lazy(() => import('pages/customerticket/mycase/MyCase')),
);
const HistoryCase = Loadable(
  lazy(() => import('pages/customerticket/historycase/HistoryCase')),
);
const CustomerChannelManagement = Loadable(
  lazy(
    () =>
      import(
        'pages/customerticket/customerchannelmanagement/CustomerChannelManagement'
      ),
  ),
);
const SendbirdCustomerDetail = Loadable(
  lazy(
    () =>
      import(
        'pages/customerticket/customerchannelmanagement/customerchanneldetail/CustomerChannelDetail'
      ),
  ),
);

const generateCustomerTicketRoutes = (loginUser: IUserData | null) => {
  const permissions = (loginUser?.permissions || []) as Auth[];
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!loginUser) {
    return null;
  }

  return {
    path: Route.customerticket,
    element: <CustomerTicket />,
    children: (() => {
      const firstFolder: RouteObject[] = [
        {
          element: <Navigate to={path.CUSTOMER_TICKET_MY_CASE} />,
          index: true,
        },
      ];

      if (includeAuth(ticketsRead) || includeAuth(ticketsEdit)) {
        firstFolder.push({
          path: FirstRoute.instantcase,
          element: <InstantCase />,
        });
      }

      firstFolder.push({
        path: FirstRoute.mycase,
        element: <MyCase />,
      });

      if (
        includeAuth(historyTicketsRead) ||
        includeAuth(historyTicketsExport)
      ) {
        firstFolder.push({
          path: FirstRoute.historycase,
          element: <HistoryCase />,
        });
      }

      (includeAuth(parentMessageRead) ||
        includeAuth(parentTicketsNew) ||
        includeAuth(parentTicketsHistoryRead)) &&
        firstFolder.push({
          path: FirstRoute.memberchannelmanagement,
          children: [
            {
              element: <CustomerChannelManagement />,
              index: true,
            },
            {
              path: SecondRoute.memberchanneldetail,
              children: [
                {
                  element: <Navigate to={Route.notFound} />,
                  index: true,
                },
                {
                  path: Route.id,
                  element: <SendbirdCustomerDetail />,
                },
              ],
            },
          ],
        });

      return firstFolder;
    })(),
  };
};

export { generateCustomerTicketRoutes };
