import { lazy } from 'react';
import { Route } from 'routes/path';
import { Loadable } from '../utils';

const RealtimeCourseCarousel = Loadable(
  lazy(() => import('pages/display/realtime-course-carousel')),
);

const generateDisplayRoutes = () => {
  return {
    path: Route.display,
    element: <RealtimeCourseCarousel />,
  };
};

export { generateDisplayRoutes };
