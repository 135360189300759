import { AxiosInstance } from 'axios';

/**
 * https://stream-course-video-dev.oneclass.com.tw/api/docs
 */

export default (axios: AxiosInstance, url: string) => ({
  restartReplayCourse(courseId: string) {
    return axios.post(
      `${url}/stream-course-video/${courseId}/replay-course/restart`,
    );
  },
});
