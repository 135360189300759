import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import { LeaveStatus } from '@type/leave';
import {
  GetLeaveListParams,
  GetLeaveListResponse,
  GetLeaveLogParams,
  GetLeaveLogResponse,
  GetStudentSchedulesParams,
  LeaveOrderDetail,
  StudentSchedule,
  UpdateLeaveDetailResponse,
} from './type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  getLeaveList(params: GetLeaveListParams): TApiResponse<GetLeaveListResponse> {
    return axios.get(`${apiPrefix}/backend/leave-order`, { params });
  },
  getLeaveDetailById(id: ObjectId): TApiResponse<LeaveOrderDetail> {
    return axios.get(`${apiPrefix}/backend/leave-order/${id}`);
  },
  getLeaveLog(params: GetLeaveLogParams): TApiResponse<GetLeaveLogResponse> {
    return axios.get(`${apiPrefix}/backend/leave-order-log`, { params });
  },
  updateLeaveDetail(
    id: ObjectId,
    data: {
      remark?: string;
      status?: LeaveStatus;
    },
  ): TApiResponse<UpdateLeaveDetailResponse> {
    return axios.patch(`${apiPrefix}/backend/leave-order/${id}`, data);
  },
  getStudentSchedules(
    params: GetStudentSchedulesParams,
  ): TApiResponse<StudentSchedule[]> {
    return axios.get(`${apiPrefix}/backend/student-schedules`, { params });
  },
  /** 學生行事曆 不包含邊界 需求來源：https://team-oneclass.atlassian.net/browse/ONECLUB-10359 */
  getStudentSchedulesNotIncludeBoundaries(
    params: GetStudentSchedulesParams,
  ): TApiResponse<StudentSchedule[]> {
    return axios.get(
      `${apiPrefix}/backend/student-schedules/notIncludeBoundaries`,
      { params },
    );
  },
});
