export const myColorSet = {
  '--sendbird-light-primary-500': '#091A7A',
  '--sendbird-light-primary-400': '#1939B7',
  '--sendbird-light-primary-300': '#3366FF',
  '--sendbird-light-primary-200': '#84A9FF',
  '--sendbird-light-primary-100': '#D6E4FF',
};
export const LINE_CHANNEL_TYPES = [
  import.meta.env.VITE_LINE_CHANNEL_TYPE_PENDING_ID,
  import.meta.env.VITE_LINE_CHANNEL_TYPE_PROCESSING_ID,
  import.meta.env.VITE_LINE_CHANNEL_TYPE_ACCEPTED_ID,
];
export const PROGRESSING_TICKET_ID = import.meta.env.VITE_PROGRESSING_TICKET_ID;
export const CUSTOMER_TYPE = import.meta.env.VITE_IM_CUSTOMER_TYPE;
export const SENDBIRD_APP_ID = import.meta.env.VITE_SENDBIRD_APP_ID;
export const NOTIFICATION_OPEN_CHANNEL_URL = import.meta.env
  .VITE_IM_NOTIFICATION_OPEN_CHANNEL_URL;

export const UNREAD_COUNT_KEYS = {
    CUSTOMER_CHANNEL: 'customerChannel',
    TICKET_CHANNEL: 'ticketChannel',
    TEACHER_CHANNEL: 'teacherChannel',
    LINE_CHANNEL: 'lineChannel',
  } as const;
