import { daysOfWeek } from 'constants/common';

export const removeUndefinedFromObject = (obj: any) => {
  const getIsObject = (value: any) => {
    const isNull = value === null;
    const isArray = value instanceof Array;
    if (typeof value === 'object' && !isNull && !isArray) {
      return true;
    } else {
      return false;
    }
  };
  const isObject = getIsObject(obj);
  if (!isObject) return obj;

  const result: any = {};
  Object.keys(obj).forEach(key => {
    const isObject = getIsObject(obj);
    const isNull = obj[key] === null;
    const isUndefined = obj[key] === undefined;
    const isEmpty = obj[key] === '';
    isObject
      ? (result[key] = removeUndefinedFromObject(obj[key]))
      : (result[key] = obj[key]);
    (isNull || isUndefined || isEmpty) && delete result[key];
  });
  return result;
};

export const transformObjectToSelectOptions = (obj: Object) => {
  const emptyOption = [
    {
      name: 'None',
      value: '',
    },
  ];
  const options = Object.entries(obj).map(([key, value]) => {
    // if value is function, run it
    if (typeof value === 'function') {
      return {
        name: value(),
        value: key,
      };
    } else {
      return {
        name: value,
        value: key,
      };
    }
  });
  return emptyOption.concat(options);
};

export const weekName = (weekDay: number) => {
  return daysOfWeek[weekDay];
};

export const isValidDate = (d: any) => {
  return !isNaN(d) && d instanceof Date;
};

export const spreadDeepObject = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

export const delay = (ms = 1000) => {
  return new Promise(resolve => {
    setTimeout(() => resolve(''), ms);
  });
};

export const navigateTo = (path: string) => {
  window.open(path, '_blank', 'noopener');
};

export const transformObjectToSelectOptionsWithoutEmptyOption = (
  objValue: Object,
  objLabel: {
    [key: string]: string;
  },
) => {
  return Object.values(objValue).map(item => {
    return {
      label: objLabel[item],
      value: item,
    };
  });
};
