import { AxiosInstance } from 'axios';
import { TApiResponse } from 'src/@type/common';
import { DrawdownFileType } from './type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /** 上傳動撥檔案 */
  uploadFile(type: DrawdownFileType, file: File): TApiResponse<boolean> {
    const formData = new FormData();

    // In order to use chinese filename, backend need to use `decodeURIComponent(...)` to decode the filename.
    formData.append('file', file, encodeURIComponent(file.name));

    return axios.post(`${apiPrefix}/drawdown/${type}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },
});
