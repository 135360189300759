import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import {
  DeliveryExtraContentParamsDTO,
  DeliveryExtraContentResponseDTO,
} from './type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /** 查詢額外項目列表 */
  deliveryExtraContent(
    params: DeliveryExtraContentParamsDTO,
  ): TApiResponse<DeliveryExtraContentResponseDTO> {
    return axios.get(`${apiPrefix}/backend/delivery-extra-content`, { params });
  },
});
