import { PropsWithChildren, Fragment, useMemo } from 'react';
import { useLoginService } from 'services/LoginService';
export interface AuthGuardProps {
  allowedPermissions: string[];
  allMatch: boolean;
}
export const AuthGuard = ({
  allowedPermissions,
  allMatch,
  children,
}: PropsWithChildren<AuthGuardProps>) => {
  const { loginUser } = useLoginService();
  const userPermissions = useMemo(() => {
    return loginUser?.permissions || [];
  }, [loginUser]);

  return (
    <>
      {allMatch &&
      allowedPermissions.every(permission =>
        userPermissions.includes(permission),
      ) ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <Fragment>{children}</Fragment>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <Fragment />
      )}
      {!allMatch &&
      allowedPermissions.some(permission =>
        userPermissions.includes(permission),
      ) ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <Fragment>{children}</Fragment>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <Fragment />
      )}
    </>
  );
};
