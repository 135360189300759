import { AxiosInstance } from 'axios';
import {
  CreateOrganizationPayload,
  CreateOrganizationPurchasePlansPayload,
  CreateOrganizationPurchasePlansResponse,
  CreateOrganizationResponse,
  GetOrganizationAdminsByUidPayload,
  GetOrganizationAdminsByUidResponse,
  GetOrganizationPurchasePlanByPagePayload,
  GetOrganizationPurchasePlanByPageResponse,
  GetOrganizationPurchasePlanByUidPayload,
  GetOrganizationPurchasePlanByUidResponse,
  GetOrganizationPurchasePlansResponse,
  GetOrganizationsAdminsByPagePayload,
  GetOrganizationsAdminsByPageResponse,
  GetPermissionsResponse,
  UpdateOrganizationPayload,
  UpdateOrganizationPurchasePlansPayload,
  UpdateOrganizationPurchasePlansResponse,
  UpdateOrganizationResponse,
} from 'api/link-plus/types';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  createOrganization(
    payload: CreateOrganizationPayload,
  ): CreateOrganizationResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent('admin/organization/create')}`,
      payload,
    );
  },
  updateOrganization(
    payload: UpdateOrganizationPayload,
  ): UpdateOrganizationResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent(
        'admin/organization/update/info/byUid',
      )}`,
      payload,
    );
  },
  getOrganizationPurchasePlans(): GetOrganizationPurchasePlansResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent(
        'admin/organizationPurchasePlan/get/info/all',
      )}`,
    );
  },
  getOrganizationPurchasePlanByUid(
    payload: GetOrganizationPurchasePlanByUidPayload,
  ): GetOrganizationPurchasePlanByUidResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent(
        'admin/organizationPurchasePlan/get/info/byUid',
      )}`,
      payload,
    );
  },
  getOrganizationPurchasePlanByPage(
    payload: GetOrganizationPurchasePlanByPagePayload,
  ): GetOrganizationPurchasePlanByPageResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent(
        'admin/organizationPurchasePlan/get/info/byPage',
      )}`,
      payload,
    );
  },
  getOrganizationsAdminsByPage(
    payload: GetOrganizationsAdminsByPagePayload,
  ): GetOrganizationsAdminsByPageResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent(
        'admin/organization/get/info/byPage',
      )}`,
      payload,
    );
  },
  getOrganizationAdminsByUid(
    payload: GetOrganizationAdminsByUidPayload,
  ): GetOrganizationAdminsByUidResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent(
        'admin/organization/get/all/admin/byUid',
      )}`,
      payload,
    );
  },
  getPermissions(): GetPermissionsResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent('admin/permission/get/info/all')}`,
    );
  },
  createOrganizationPurchasePlans(
    payload: CreateOrganizationPurchasePlansPayload,
  ): CreateOrganizationPurchasePlansResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent(
        'admin/organizationPurchasePlan/create',
      )}`,
      payload,
    );
  },
  updateOrganizationPurchasePlans(
    payload: UpdateOrganizationPurchasePlansPayload,
  ): UpdateOrganizationPurchasePlansResponse {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent(
        'admin/organizationPurchasePlan/update/info/byUid',
      )}`,
      payload,
    );
  },
});
