import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import { TFeedbackListPayload } from '@type/feedback.type';
import { TGetCourseFeedbackResponse } from './feedback.type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  // 課後回饋列表
  getCourseFeedback(
    params: TFeedbackListPayload,
  ): TApiResponse<TGetCourseFeedbackResponse> {
    return axios.get(`${apiPrefix}/feedback`, { params });
  },
});
