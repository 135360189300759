import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import {
  IGetHistoryTeacherParams,
  IGetHistoryTeacherResponse,
} from './student-course.type';
export default (axios: AxiosInstance, apiPrefix: string) => ({
  getHistoryTeachers(
    params: IGetHistoryTeacherParams,
  ): TApiResponse<IGetHistoryTeacherResponse> {
    return axios.get(`${apiPrefix}/studentCourse/history/teachers`, { params });
  },
});
