import { t } from 'utils/i18n';
import { orderState } from '@type/electroniccommerce/order.type';
import { EPayment } from '@type/electroniccommerce/setting.type';

export const OrderStatus = {
  paid: () => t('constants.order.orderStatus.paid', '已付款'),
  arrearage: () => t('constants.order.orderStatus.arrearage', '未付款'),
  cancel: () => t('constants.order.orderStatus.cancel', '已取消'),
  audit: () => t('constants.order.orderStatus.underReview', '審核中'),
  reject: () => t('constants.order.orderStatus.refuseToTrade', '拒絕交易'),
  [orderState.payment_order_cancel]: () =>
    t('constants.order.orderStatus.jinliuFangOrderCancelled', '金流方訂單取消'),
  [orderState.payment_trade_cancel]: () =>
    t(
      'constants.order.orderStatus.goldFlowPartyTransactionCancelled',
      '金流方交易取消',
    ),
  [orderState.authorization_failed]: () =>
    t('constants.order.orderStatus.jinliuFangOrderFailed', '金流方交易失敗'),
  [orderState.refund_applying]: () =>
    t('constants.order.orderStatus.refundApplying', '退款申請中'),
  [orderState.refund_pending]: () =>
    t('constants.order.orderStatus.refundPending', '待退款'),
  [orderState.refunded]: () =>
    t('constants.order.orderStatus.refunded', '已退款'),
  terminate: () => t('constants.order.orderStatus.terminate', '已終止'),
};

export const ContractLabel: { [key in orderState]?: string } = {
  [orderState.paid]: '正式合約',
  [orderState.arrearage]: '草約',
};

export const DateType = {
  create: () => t('constants.order.dateType.create', '建立時間'),
  pay: () => t('constants.order.dateType.pay', '付款時間'),
};

export enum Payment {
  newebpay = '藍新', // 藍新
  ecpay = '綠界', // 綠界
  chailease = '銀角', // 銀角
  farTrust = '遠信', // 遠信
  paynow = 'Paynow', // Paynow
  transfer = '轉帳', // 轉帳
  payuni = 'PAYUNi統一金流', // Payuni
}

export const PaymentText = {
  newebpay: () => t('constants.order.payment.newebpay', '藍新'), // 藍新
  ecpay: () => t('constants.order.payment.ecpay', '綠界'), // 綠界
  chailease: () => t('constants.order.payment.chailease', '銀角'), // 銀角
  farTrust: () => t('constants.order.payment.farTrust', '遠信'), // 遠信
  paynow: () => t('constants.order.payment.paynow', 'Paynow'), // Paynow
  transfer: () => t('constants.order.payment.transfer', '轉帳'), // 轉帳
  payuni: () => t('constants.order.payment.payuni', 'PAYUNi統一金流'), // PAYUNi
};

export enum EOrderRecordType {
  CREATE = 'create',
  MODIFY = 'modify',
  SEND_PREVIEW = 'sendPreview',
  TRANSFER = 'transfer',
  ENTER_TRANSFER_INFORMATION = 'enterTransferInformation',
  CREATE_CONTRACT_EXTENSION = 'createContractExtension',
  DELETE_CONTRACT_EXTENSION = 'deleteContractExtension',
  TERMINATE = 'terminate',
  REFUND_APPLYING = 'refundApplying',
  REFUND_PENDING = 'refundPending',
  REFUNDED = 'refunded',
  REFUND_CANCEL = 'refundCancel',
}

export const EOrderRecordTypeLabel = {
  [EOrderRecordType.CREATE]: '建立訂單',
  [EOrderRecordType.MODIFY]: '修改訂單',
  [EOrderRecordType.SEND_PREVIEW]: '寄送預覽合約',
  [EOrderRecordType.TRANSFER]: '訂單轉移',
  [EOrderRecordType.ENTER_TRANSFER_INFORMATION]: '登錄轉帳資訊',
  [EOrderRecordType.CREATE_CONTRACT_EXTENSION]: '合約延展上傳',
  [EOrderRecordType.DELETE_CONTRACT_EXTENSION]: '合約延展刪除',
  [EOrderRecordType.TERMINATE]: '終止展延訂單',
  [EOrderRecordType.REFUND_APPLYING]: '退款申請',
  [EOrderRecordType.REFUND_PENDING]: '退款確認',
  [EOrderRecordType.REFUNDED]: '退款完成',
  [EOrderRecordType.REFUND_CANCEL]: '退款取消',
};

export enum ContractRefundAttachmentCategory {
  SIGNATURE_ATTACHMENT_FILES = 'signedContractFiles',
  OTHER_ATTACHMENT_FILES = 'otherAttachmentsFiles',
}

export enum ContractExtensionAttachmentCategory {
  CONTRACT_FILES = 'contractFiles',
  UPDATED_CONTRACTS = 'updatedContracts',
}

/**
 * @description 發票形式
 * @param duplicate 二聯式
 * @param triplicate 三聯式
 * @param cloud 雲端電子發票
 * @param other 其他
 */
export enum EInvoiceType {
  DUPLICATE = 'duplicate',
  TRIPLICATE = 'triplicate',
  CLOUD = 'cloud',
  OTHER = 'other',
}
export const INVOICE_TYPE = {
  [EInvoiceType.DUPLICATE]: '二聯發票',
  [EInvoiceType.TRIPLICATE]: '三聯發票',
  [EInvoiceType.CLOUD]: '電子發票',
  [EInvoiceType.OTHER]: '其他',
};

type LabelColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

export const OrderStatusColor: { [key in orderState]: LabelColor } = {
  [orderState.paid]: 'success',
  [orderState.arrearage]: 'error',
  [orderState.cancel]: 'default',
  [orderState.audit]: 'success',
  [orderState.reject]: 'error',
  [orderState.payment_order_cancel]: 'error',
  [orderState.payment_trade_cancel]: 'error',
  [orderState.authorization_failed]: 'error',
  [orderState.refund_applying]: 'warning',
  [orderState.refund_pending]: 'warning',
  [orderState.refunded]: 'warning',
};

type TextType = {
  [key in orderState]: {
    orderStatus: () => string;
    contract: () => string;
    mail: () => string;
  };
};
export const OrderStateText: TextType = {
  [orderState.paid]: {
    orderStatus: () =>
      t('electronicCommerce.order.components.orderState.alreadyPaid', '已付款'),
    contract: () =>
      t(
        'electronicCommerce.order.components.orderState.previewOfficialContract',
        '預覽正式合約',
      ),
    mail: () =>
      t(
        'electronicCommerce.order.components.orderState.sendFormalContract',
        '寄送正式合約',
      ),
  },
  [orderState.arrearage]: {
    orderStatus: () =>
      t('electronicCommerce.order.components.orderState.unpaid', '未付款'),
    contract: () =>
      t(
        'electronicCommerce.order.components.orderState.previewDraftAppointment',
        '預覽草約',
      ),
    mail: () =>
      t(
        'electronicCommerce.order.components.orderState.sendDraftAppointment',
        '寄送草約',
      ),
  },
  [orderState.cancel]: {
    orderStatus: () =>
      t('electronicCommerce.order.components.orderState.cancelled', '已取消'),
    contract: () => '',
    mail: () => '',
  },
  [orderState.audit]: {
    orderStatus: () =>
      t('electronicCommerce.order.components.orderState.underReview', '審核中'),
    contract: () => '',
    mail: () => '',
  },
  [orderState.reject]: {
    orderStatus: () =>
      t(
        'electronicCommerce.order.components.orderState.refuseToTrade',
        '拒絕交易',
      ),
    contract: () => '',
    mail: () => '',
  },
  [orderState.payment_order_cancel]: {
    orderStatus: () =>
      t(
        'electronicCommerce.order.components.orderState.jinliuFangOrderCancellation',
        '金流方訂單取消',
      ),
    contract: () => '',
    mail: () => '',
  },
  [orderState.payment_trade_cancel]: {
    orderStatus: () =>
      t(
        'electronicCommerce.order.components.orderState.goldFlowPartyTransactionCancelled',
        '金流方交易取消',
      ),
    contract: () => '',
    mail: () => '',
  },
  [orderState.authorization_failed]: {
    orderStatus: () =>
      t(
        'electronicCommerce.order.components.orderState.jinliuFangOrderFailed',
        '金流方交易失敗',
      ),
    contract: () => '',
    mail: () => '',
  },
  [orderState.refund_applying]: {
    orderStatus: () =>
      t(
        'electronicCommerce.order.components.orderState.refundApplying',
        '退款申請中',
      ),
    contract: () =>
      t(
        'electronicCommerce.order.components.orderState.previewOfficialContract',
        '預覽正式合約',
      ),
    mail: () =>
      t(
        'electronicCommerce.order.components.orderState.sendFormalContract',
        '寄送正式合約',
      ),
  },
  [orderState.refund_pending]: {
    orderStatus: () =>
      t(
        'electronicCommerce.order.components.orderState.refundPending',
        '待退款',
      ),
    contract: () =>
      t(
        'electronicCommerce.order.components.orderState.previewOfficialContract',
        '預覽正式合約',
      ),
    mail: () =>
      t(
        'electronicCommerce.order.components.orderState.sendFormalContract',
        '寄送正式合約',
      ),
  },
  [orderState.refunded]: {
    orderStatus: () =>
      t('electronicCommerce.order.components.orderState.refunded', '已退款'),
    contract: () =>
      t(
        'electronicCommerce.order.components.orderState.previewOfficialContract',
        '預覽正式合約',
      ),
    mail: () =>
      t(
        'electronicCommerce.order.components.orderState.sendFormalContract',
        '寄送正式合約',
      ),
  },
};

// 付款方式
export const OrderPaymentText: {
  [key in EPayment]: (paymentMethod?: string) => string;
} = {
  [EPayment.newebpay]: () =>
    t(
      'electronicCommerce.order.components.orderState.paymentText.newebpay',
      '信用卡付清',
    ),
  [EPayment.ecpay]: () =>
    t(
      'electronicCommerce.order.components.orderState.paymentText.ecpay',
      '信用卡付清',
    ),
  [EPayment.transfer]: () =>
    t(
      'electronicCommerce.order.components.orderState.paymentText.transfer',
      '轉帳付款',
    ),
  [EPayment.chailease]: () =>
    t(
      'electronicCommerce.order.components.orderState.paymentText.chailease',
      '銀角零卡付款',
    ),
  [EPayment.farTrust]: () =>
    t(
      'electronicCommerce.order.components.orderState.paymentText.farTrust',
      '遠信免卡付款',
    ),
  [EPayment.noCashTrade]: () =>
    t(
      'electronicCommerce.order.components.orderState.paymentText.noCashTrade',
      '0元訂單',
    ),
  [EPayment.paynow]: () =>
    t(
      'electronicCommerce.order.components.orderState.paymentText.payNow',
      '定期定額',
    ),
  [EPayment.payuni]: paymentMethod => {
    switch (paymentMethod) {
      case 'credit':
        return t(
          'electronicCommerce.order.components.orderState.paymentText.ecpay',
          '信用卡付清',
        );
      case 'costAveraging':
        return t(
          'electronicCommerce.order.components.orderState.paymentText.payNow',
          '定期定額',
        );
      default:
        return t(
          'electronicCommerce.order.components.orderState.paymentText.ecpay',
          '信用卡付清',
        );
    }
  },
};

export enum CONTRACT_EXTENSION_STATUS {
  PAID = 'paid',
  ARREARAGE = 'arrearage',
  AUTHORIZATION_FAILED = 'authorization-failed',
  TERMINATE = 'terminate',
}

export enum EMAIL_NOTIFICATION_TYPE {
  Send = 'Send', // 寄送
  Delivery = 'Delivery', // 信件接收成功
  Reject = 'Reject', // 信件接收失敗（拒絕）
  Bounce = 'Bounce', // 信件接收失敗（退信）
  Open = 'Open', //  家長已開啟信件
  Complaint = 'Complaint', // 垃圾信件
  DeliveryDelay = 'DeliveryDelay', // 其他（投遞延遲）
  Click = 'Click', // 點擊 => 未啟用
  Subscription = 'Subscription', // 訂閱 => 未啟用
  'Rendering Failure' = 'Rendering Failure', // 渲染失敗
}

export const EMAIL_NOTIFICATION_TYPE_LABEL = {
  [EMAIL_NOTIFICATION_TYPE.Send]: '寄送',
  [EMAIL_NOTIFICATION_TYPE.Delivery]: '信件接收成功',
  [EMAIL_NOTIFICATION_TYPE.Reject]: '信件接收失敗（拒絕）',
  [EMAIL_NOTIFICATION_TYPE.Bounce]: '信件接收失敗（退信）',
  [EMAIL_NOTIFICATION_TYPE.Open]: '家長已開啟信件',
  [EMAIL_NOTIFICATION_TYPE.Complaint]: '垃圾信件',
  [EMAIL_NOTIFICATION_TYPE.DeliveryDelay]: '其他（投遞延遲）',
  [EMAIL_NOTIFICATION_TYPE.Click]: '點擊',
  [EMAIL_NOTIFICATION_TYPE.Subscription]: '訂閱',
  [EMAIL_NOTIFICATION_TYPE['Rendering Failure']]: '渲染失敗',
};

export enum EMAIL_OVERDUE_CATEGORY {
  OVERDUE_CONTRACT = 'overdueContract', // 合約即將逾期通知信
  OVERDUE_NEARLY_PAYMENT = 'overdueNearlyPayment', // 繳款逾期催繳通知信
  OVERDUE_PAYMENT = 'overduePayment', //  繳款逾期帳號停用通知信
}

export const EMAIL_OVERDUE_CATEGORY_LABEL = {
  [EMAIL_OVERDUE_CATEGORY.OVERDUE_CONTRACT]: '合約即將逾期通知信',
  [EMAIL_OVERDUE_CATEGORY.OVERDUE_NEARLY_PAYMENT]: '繳款逾期催繳通知信',
  [EMAIL_OVERDUE_CATEGORY.OVERDUE_PAYMENT]: '繳款逾期帳號停用通知信',
};
