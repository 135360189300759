import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import {
  TGetRecordingTimeResponse,
  TGetVideoUserParams,
  TGetVideoUserResponse,
} from '@type/oneborad';
import { getCourseParams, CourseInfo } from './one-board/course/course.type';

export default (axios: AxiosInstance, oneBoard: string) => ({
  /**
   * 取得 oneboard record
   */
  getMultiUserRecord(courseId: string): any {
    return axios.post(`${oneBoard}/oneboard/course/multiUserRecord`, {
      courseId,
    });
  },

  /**
   * 取得 oneboard 錄影時間
   */
  getRecordingTime(courseId: string): TApiResponse<TGetRecordingTimeResponse> {
    return axios.get(
      `${oneBoard}/agora/get_recording_times?roomId=${courseId}`,
    );
  },
  // oneBoard 沒 swagger 文件可看型別，先用目前回傳資料設定型別
  getVideoUser(data: TGetVideoUserParams): TApiResponse<TGetVideoUserResponse> {
    return axios.get(
      `${import.meta.env.VITE_ONEBOARD_API_URL}/oneboard/course`,
      { params: data },
    );
  },
  /**
   * 取得 oneboard 課程資訊
   */
  getCourse(params: getCourseParams): TApiResponse<CourseInfo> {
    return axios.get(`${oneBoard}/oneboard/course`, { params });
  },
});
