import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import path, {
  Auth,
  FirstRoute,
  SecondRoute,
  ThirdRoute,
  Route,
} from 'routes/path';
import { Loadable } from 'routes/utils';
import { checkPermissionsInAuth } from 'utils/helpr/permission';

const CourseClaim = Loadable(
  lazy(() => import('pages/audition/course-claim/course-claim')),
);

const CourseClaimFormalCreate = Loadable(
  lazy(
    () =>
      import('pages/audition/course-claim/course-claim-formal-create/index'),
  ),
);

const CourseClaimFormalRead = Loadable(
  lazy(
    () => import('pages/audition/course-claim/course-claim-formal-read/index'),
  ),
);

const CourseClaimManagement = Loadable(
  lazy(
    () => import('pages/audition/course-claim/course-claim-management/index'),
  ),
);

const CourseClaimTrialRead = Loadable(
  lazy(
    () => import('pages/audition/course-claim/course-claim-trial-read/index'),
  ),
);

const CourseClaimTrialCreate = Loadable(
  lazy(
    () => import('pages/audition/course-claim/course-claim-trial-create/index'),
  ),
);

const generateCourseClaimFormalRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);
  const routes: RouteObject[] = [
    {
      element: <Navigate to={path.AUDITION_COURSE_CLAIM} />,
      index: true,
    },
  ];

  if (includeAuth([Auth.COURSE_CLAIM_CREATE])) {
    routes.push({
      path: ThirdRoute.create,
      element: <CourseClaimFormalCreate />,
    });
    routes.push({
      path: ThirdRoute.copy,
      children: [
        {
          element: <Navigate to={path.NOT_FOUND} />,
          index: true,
        },
        {
          path: Route.id,
          element: <CourseClaimFormalCreate />,
        },
      ],
    });
  }

  if (includeAuth([Auth.COURSE_CLAIM_READ])) {
    routes.push({
      path: ThirdRoute.read,
      children: [
        {
          element: <Navigate to={path.NOT_FOUND} />,
          index: true,
        },
        {
          path: Route.id,
          element: <CourseClaimFormalRead />,
        },
      ],
    });
  }

  return routes;
};

const generateCourseClaimTrialRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);
  const routes: RouteObject[] = [
    {
      element: <Navigate to={path.AUDITION_COURSE_CLAIM} />,
      index: true,
    },
  ];
  if (includeAuth([Auth.COURSE_AUDITION_CLAIM_READ])) {
    routes.push({
      path: ThirdRoute.read,
      children: [
        {
          element: <Navigate to={path.NOT_FOUND} />,
          index: true,
        },
        {
          path: Route.id,
          element: <CourseClaimTrialRead />,
        },
      ],
    });
  }

  if (includeAuth([Auth.COURSE_AUDITION_CLAIM_CREATE])) {
    routes.push({
      path: ThirdRoute.copy,
      children: [
        {
          element: <Navigate to={path.NOT_FOUND} />,
          index: true,
        },
        {
          path: Route.id,
          element: <CourseClaimTrialCreate />,
        },
      ],
    });
  }

  return routes;
};

export const generateCourseClaimRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth([Auth.COURSE_CLAIM_READ, Auth.COURSE_AUDITION_CLAIM_READ]))
    return null;

  return {
    path: FirstRoute.courseclaim,
    element: <CourseClaim />,
    children: (() => {
      const secondFolder: RouteObject[] = [
        {
          element: <CourseClaimManagement />,
          index: true,
        },
        {
          path: SecondRoute.formal,
          children: generateCourseClaimFormalRoutes(permissions),
        },
        {
          path: SecondRoute.trial,
          children: generateCourseClaimTrialRoutes(permissions),
        },
      ];

      return secondFolder;
    })(),
  };
};
