export enum EApiStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum TTL {
  ONE_SECOND = 1000,
  ONE_MINUTE = 60 * ONE_SECOND,
  ONE_HOUR = 60 * ONE_MINUTE,
  ONE_DAY = 24 * ONE_HOUR,
}

export const daysOfWeek = ['日', '一', '二', '三', '四', '五', '六'];

export const DIRECTION_TYPE = {
  ASC: 'asc',
  DESC: 'desc',
} as const;
