import { ERROR_CODES } from 'constants/oneAcademy';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  createErrorParser,
  deleteToken,
  setToken,
  showSnackbar,
} from 'utils/oneacademy-cms/functions';
import cookies, { KeyCookie } from 'plugins/cookie/cookie';
import path from 'routes/path';

const instance = axios.create();

const handleProxyAPI = (data: any) => {
  const errorCode = data.error;
  if (!errorCode) {
    // 若 token 過期，response 會帶 token，
    // 這一次 request 仍算，下一次之後就要用新 token
    const newToken = data.token;
    if (newToken) {
      setToken(newToken);
    }
    return data;
  }
  switch (errorCode) {
    case 3401:
      if (window.location.pathname !== '/login') {
        window.location.replace('/login');
      }
      deleteToken();
      showSnackbar(ERROR_CODES['3401'], 'error');
      break;
    case 3414: {
      const parsedErrorCode = createErrorParser(data.data.message);
      if (parsedErrorCode) {
        showSnackbar(
          ERROR_CODES?.[
            parsedErrorCode as unknown as keyof typeof ERROR_CODES
          ] || String(parsedErrorCode),
          'error',
        );
      }
      break;
    }
    case 3418: {
      throw (
        ERROR_CODES?.[errorCode as keyof typeof ERROR_CODES] ||
        String(errorCode)
      );
    }
    default:
      showSnackbar(
        ERROR_CODES?.[errorCode as keyof typeof ERROR_CODES] ||
          String(errorCode),
        'error',
      );
      break;
  }
  throw errorCode;
};

const onRequest = (config: AxiosRequestConfig) => {
  const accessToken = cookies.get(KeyCookie.oneClassMMSClient)?.accessToken;

  if (config.headers && accessToken) {
    config.headers.Authorization = accessToken;
  }

  // remove empty params, params may be array, null or undefined
  if (config.params) {
    const _params = { ...config.params };
    Object.keys(_params).forEach(key => {
      if (
        _params[key] === null ||
        _params[key] === undefined ||
        (Array.isArray(_params[key]) &&
          _params[key].filter(
            (el: any) => el !== null && el !== undefined && el !== '',
          ).length === 0)
      ) {
        delete _params[key];
      }
    });
    config.params = _params;
  }

  return config;
};

const onResponse = (response: AxiosResponse) => {
  const { status, data } = response;
  if (status >= 500 || data?.error?.errorCode === 500) {
    return window.location.replace(path.SERVER_ERROR);
  }
  if (status >= 200 && status <= 299) {
    // 處理 proxy API 的 response
    if (data?.error) {
      showSnackbar(data.error.message || String(data.error.errorCode), 'error');
    }
    return handleProxyAPI(data.data);
  }

  return response;
};

const onError = (error: AxiosError) => {
  const status = error?.response?.status || '500';

  return { status: `${status} - failure`, error };
};

instance.interceptors.request.use(onRequest);
instance.interceptors.response.use(onResponse, onError);
export default instance;
