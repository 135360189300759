import { useState } from 'react';
// hooks
import { useSettings, useResponsive, useCollapseDrawer } from '@onedesign/ui';
// config
import { useLoginService } from 'services/LoginService';
import { VerticalLayout } from './vertical-layout';
import { HorizontalLayout } from './horizontal-layout';

export const DashboardLayout = () => {
  const { loginUser, setLoginUser } = useLoginService();
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  return verticalLayout ? (
    <VerticalLayout
      loginUser={loginUser}
      setLoginUser={setLoginUser}
      isDesktop={isDesktop}
      open={open}
      setOpen={setOpen}
    />
  ) : (
    <HorizontalLayout
      loginUser={loginUser}
      setLoginUser={setLoginUser}
      collapseClick={collapseClick}
      isCollapse={isCollapse}
      open={open}
      setOpen={setOpen}
    />
  );
};
