import { AxiosInstance } from 'axios';
import admin from './admin';
import oneAnswer from './one-answer';
import organization from './organization';
import user from './user';

/*
 * 代理 link-plus API
 */

export default (axios: AxiosInstance, apiPrefix: string) => ({
  admin: admin(axios, apiPrefix),
  oneAnswer: oneAnswer(axios, apiPrefix),
  organization: organization(axios, apiPrefix),
  user: user(axios, apiPrefix),
});
