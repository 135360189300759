import { type AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import {
  DeliveryRecordsResponseDTO,
  GetDeliveriesRecordsParamsDTO,
} from './type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  getDeliveriesRecordsById(
    params: GetDeliveriesRecordsParamsDTO,
  ): TApiResponse<DeliveryRecordsResponseDTO> {
    return axios.get(`${apiPrefix}/backend/delivery-events`, { params });
  },
});
