import axios from 'plugins/api/axios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

const { notificationCenter } = axios;

export const useGetNotifications = (
  params: {
    skip: number;
    limit: number;
  },
  enabled: boolean,
) => {
  return useQuery({
    queryKey: ['notification', params],
    enabled,
    retry: 1,
    queryFn: async () => {
      return await notificationCenter
        .getNotifications(params)
        .then(response => {
          const { data, err } = response;
          if (err !== 0 || !data) return null;
          return data || [];
        });
    },
  });
};

export const useMarkAsReadNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => {
      return notificationCenter.markAsReadNotification(id);
    },
    onSuccess: ({ err, msg }) => {
      if (err !== 0) Promise.reject(new Error(msg));

      queryClient.invalidateQueries({
        queryKey: ['notification'],
      });
    },
  });
};

export const useMarkAllAsReadNotifications = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return notificationCenter.markAllAsReadNotifications();
    },
    onSuccess: ({ err, msg }) => {
      if (err !== 0) Promise.reject(new Error(msg));

      queryClient.invalidateQueries({
        queryKey: ['notification'],
      });
    },
  });
};
