import { AxiosInstance } from 'axios';
import { TApiResponse } from 'src/@type/common';
import {
  GetCourseClaimParamsDTO,
  GetCourseClaimResponseDTO,
  CreateCourseClaimPayload,
  CreateCourseClaimResponse,
  CourseClaimItemDTO,
  GetCourseClaimTrialParamsDTO,
  GetCourseClaimClaimResponseDTO,
  CreateCourseClaimTrialPayload,
  CourseClaimTrialDetailDTO,
} from './course-claim.type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /** 取得一般課搶課單列表 */
  getCourseClaim(
    params?: GetCourseClaimParamsDTO,
  ): TApiResponse<GetCourseClaimResponseDTO> {
    return axios.get(`${apiPrefix}/backend/course-claim`, { params });
  },

  /** 取得試聽課搶課單列表 */
  getCourseClaimTrial(
    params?: GetCourseClaimTrialParamsDTO,
  ): TApiResponse<GetCourseClaimClaimResponseDTO> {
    return axios.get(`${apiPrefix}/backend/course-claim`, { params });
  },

  /** 新增搶課單 */
  createCourseClaim(
    data: CreateCourseClaimPayload,
  ): TApiResponse<CreateCourseClaimResponse> {
    return axios.post(`${apiPrefix}/backend/course-claim`, data);
  },

  /** 新增試聽搶課單 */
  createCourseClaimTrial(
    data: CreateCourseClaimTrialPayload,
  ): TApiResponse<unknown> {
    return axios.post(`${apiPrefix}/backend/course-claim/audition`, data);
  },

  /** 取得搶課單 by id */
  getCourseClaimById(id: string): TApiResponse<CourseClaimItemDTO> {
    return axios.get(`${apiPrefix}/backend/course-claim/${id}`);
  },

  /** 取得 試聽課 搶課單  */
  getCourseClaimTrialById(id: string): TApiResponse<CourseClaimTrialDetailDTO> {
    return axios.get(`${apiPrefix}/backend/course-claim/${id}`);
  },

  /** 取消搶課單 */
  cancelCourseClaim(id: string): TApiResponse<true> {
    return axios.patch(`${apiPrefix}/backend/course-claim/cancel/${id}`);
  },
});
