// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import 'react-toastify/dist/ReactToastify.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// contexts
import { SettingsProvider, CollapseDrawerProvider } from '@onedesign/ui';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// config
import { defaultSettings } from './config';
import colorPresets from './theme/colorPresets';

import App from './App';

// ----------------------------------------------------------------------

const Main = () => (
  <HelmetProvider>
    <SettingsProvider
      colorPresets={colorPresets}
      defaultSettings={defaultSettings}
    >
      <CollapseDrawerProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LocalizationProvider>
      </CollapseDrawerProvider>
    </SettingsProvider>
  </HelmetProvider>
);

ReactDOM.render(<Main />, document.getElementById('root'));
