import { AxiosInstance } from 'axios';
import { TApiResponse } from 'src/@type/common';
import {
  DiscountInvoiceRequestDTO,
  DiscountInvoiceResponseDTO,
  VoidInvoiceRequestDTO,
  VoidInvoiceResponseDTO,
  InvoiceInformationResponseDTO,
  VoidDiscountInvoiceRequestDTO,
  VoidDiscountInvoiceResponseDTO,
  CreateSupplementaryInvoiceRequestDTO,
  CreateSupplementaryInvoiceResponseDTO,
} from './type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /** 作廢發票 */
  voidInvoice(
    invoiceId: string,
    payload: VoidInvoiceRequestDTO,
  ): TApiResponse<VoidInvoiceResponseDTO> {
    return axios.post(`${apiPrefix}/invoice/${invoiceId}/void`, payload);
  },

  /** 折讓發票 */
  discountInvoice(
    invoiceId: string,
    payload: DiscountInvoiceRequestDTO,
  ): TApiResponse<DiscountInvoiceResponseDTO> {
    return axios.post(`${apiPrefix}/invoice/${invoiceId}/discount`, payload);
  },

  /** 發票詳情 */
  getInvoiceInformation(
    invoiceId: string,
  ): TApiResponse<InvoiceInformationResponseDTO> {
    return axios.get(`${apiPrefix}/invoice/${invoiceId}/information`);
  },

  /** 取消折讓發票 */
  voidDiscountInvoice(
    invoiceId: string,
    payload: VoidDiscountInvoiceRequestDTO,
  ): TApiResponse<VoidDiscountInvoiceResponseDTO> {
    return axios.post(
      `${apiPrefix}/invoice/${invoiceId}/voidDiscount`,
      payload,
    );
  },

  /** 開立補繳款發票 */
  createSupplementaryInvoice(
    orderId: string,
    payload: CreateSupplementaryInvoiceRequestDTO,
  ): TApiResponse<CreateSupplementaryInvoiceResponseDTO> {
    return axios.post(
      `${apiPrefix}/invoice/${orderId}/issueInvoice/suppl`,
      payload,
    );
  },
});
