import { AxiosInstance } from 'axios';
import {
  UpdateOneAnswerUnlimitedPayload,
  GetOneAnswerData,
  UpdateOneAnswerAddPoints,
  Response,
} from 'api/link-plus/types';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  updateOneAnswerUnlimited(
    payload: UpdateOneAnswerUnlimitedPayload,
  ): Response<GetOneAnswerData> {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent(
        'admin/organization/oaIssue/upd/unlimitedIssue/byOrganizationUid',
      )}`,
      payload,
    );
  },
  updateOneAnswerAddPoints(
    payload: UpdateOneAnswerAddPoints,
  ): Response<GetOneAnswerData> {
    return axios.post(
      `${apiPrefix}/${encodeURIComponent(
        'admin/organization/oaIssue/add/issuePoints/byOrganizationUid',
      )}`,
      payload,
    );
  },
});
