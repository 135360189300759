import { AxiosInstance } from 'axios';
import { TSBApiResponse } from '@type/common';
import {
  GetNotificationsParams,
  GetNotificationsResponse,
} from './notification-center.type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  getNotifications(
    params: GetNotificationsParams,
  ): TSBApiResponse<GetNotificationsResponse> {
    return axios.get(`${apiPrefix}/notifications`, {
      params,
    });
  },
  /** 將此通知改為已讀 */
  markAsReadNotification(id: string): TSBApiResponse<string> {
    return axios.patch(`${apiPrefix}/notifications/${id}`);
  },
  /** 將所有通知改為已讀 */
  markAllAsReadNotifications(): TSBApiResponse<string> {
    return axios.patch(`${apiPrefix}/notifications/markAllAsRead`);
  },
});
