import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import {
  CreateDeliveriesDTO,
  CreateDeliveriesPayloadDTO,
  SchoolYearAndSemesterDTO,
} from './type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /** 建立講義寄送單 */
  createDeliveries(
    payload: CreateDeliveriesPayloadDTO,
  ): TApiResponse<CreateDeliveriesDTO> {
    return axios.post(`${apiPrefix}/backend/deliveries`, payload);
  },

  /** 取得學年學期 */
  getSchoolYearAndSemester(): TApiResponse<SchoolYearAndSemesterDTO> {
    return axios.get(`${apiPrefix}/backend/deliveries/schoolYearAndSemester`);
  },

  /**
   * 更新寄送單
   * @param id
   * @param payload
   * @returns
   */
  editDeliveries(
    id: string,
    payload: CreateDeliveriesPayloadDTO,
  ): TApiResponse<CreateDeliveriesDTO> {
    return axios.patch(`${apiPrefix}/backend/deliveries/${id}`, payload);
  },

  /** 查詢寄送單明細 */
  getDeliveriesById(id: string): TApiResponse<CreateDeliveriesDTO> {
    return axios.get(`${apiPrefix}/backend/deliveries/${id}`);
  },
});
