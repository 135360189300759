import { SendbirdInitProvider } from 'services/SendbirdService';
import { Outlet } from 'react-router-dom';

export const SendbirdChatLayout = () => {
  return (
    <SendbirdInitProvider>
      <Outlet />
    </SendbirdInitProvider>
  );
};
