import { EOrderType } from 'constants/customer.constants';
import LRU from 'lru-cache';
import { AxiosInstance } from 'axios';
import {
  IStaff,
  IStaffResponse,
  TDepartmentConsultantData,
} from '@type/staff.type';
import { IDepartment } from '@type/department.type';
import {
  ICreateCustomersParams,
  IUpdateCustomersParams,
} from '@type/customer/customerList';
import { TApiResponse, IPagnationParams, TAddressObj } from '@type/common';
import {
  ICreateStudentPayload,
  // ISubjectInformation,
  // ISession,
  ECustomerType,
} from '@type/customer/customer.type';
import {
  IUserPoints,
  IPointsRecordsRes,
} from '@type/point/PointsRepository.type';
import { Auth } from 'routes/path';

export interface IStaffParams {
  limit?: number;
  skip?: number;
  keyword?: string;
  permissions?: Auth[];
}

export interface IUpdateStaffParams {
  name?: string;
  departments?: string[];
  permissions?: string[];
  isSuspended?: boolean;
  nickname?: string;
  slackUserId?: string;
}
export interface IDepartmentParams {
  name: string;
  managers?: string[];
  roles?: string[];
  parent?: string;
}

export interface IBackstageUserPoints {
  userId: string;
}

export interface IStaffRecords extends IPagnationParams {
  keyword?: string;
}

export interface IUserPointsParams {
  changeReason: string;
  remark: string;
  point?: number;
  activePoint?: number;
  expired?: number;
  userId: string;
  productId: string;
}

export interface IPointsRecords {
  limit: number;
  skip: number;
  sessionId?: string;
}
export interface IAuthToken {
  staffNumber: string;
  password: string;
}

export interface IRegister {
  staffNumber: string;
  password: string;
  name: string;
  email: string;
}

export interface IRoleCreate {
  name: string;
}

export interface IRolePermission {
  name: string;
  permissions: string[];
}

export interface ICustomersStudent {
  createdAt: number | string;
  updatedAt: number | string;
  remark?: {
    description: string | null;
    // 原本排課偏好（科目、時段、頻率、首次上課日）
    // subjects: string[];
    // subjectInformation: ISubjectInformation[];
    // fullEnglish: boolean;
    // firstCourseTime: string | number | null;
    // sessions: ISession[];
    // frequency: number;
    schedulingPreferences: string;
    purpose: string;
    teacherPreference: string;
    degree: string;
    additional: string;
    attitude: string;
    character: string;
    condition: string;
    habit: string;
    privateSchool: string;
    teachingParents: boolean;
  };
  school?: {
    city: string;
    district: string;
    name: string;
    id?: string;
  };
  grade?: number;
  education: string;
  groupId: string;
  customerId: string;
  status: 'active' | 'disable';
  id: string;
  name: string;
  parentOneClubId: string;
  isUseZoom: boolean;
  deliveryAddress?: {
    address: string;
    country: string;
    districts: string;
  };
  GMTOffset?: string;
}

export interface IGetCustomersByOneClubIdResponse {
  type: ECustomerType;
  createdAt?: string | number;
  updatedAt?: string | number;
  oneClubId: string;
  contactId?: string;
  avatar?: string;
  name: string;
  consultant?: {
    name: string;
    id: string;
    staffNumber: string;
  };
  subConsultant?: {
    name: string;
    id: string;
  };
  tutor?: {
    name: string;
    id: string;
    staffNumber: string;
  };
  areaCode?: string;
  phone?: string;
  email?: string;
  GMTOffset?: number;
  isForeign?: boolean;
  mailingAddress?: TAddressObj;
  deliveryAddress?: TAddressObj;
  administrativeNotes?: string;
  customerDesc?: string;
  customerThunderPoint?: string;
  organization: string;
  groups?: {
    id: string;
    name: string;
  }[];
  orders?: [];
  students?: ICustomersStudent[];
  id: string;
  paymentStatus?: EOrderType;
  paidMember?: boolean;
  isOrderAndCreditAudited: boolean;
  productSettings: {
    product: string;
    features: string[];
  }[];
}

export interface IUpdateCustomers {
  id: string;
  contactId?: string;
  name?: string;
  consultant?: {
    name: string;
    id: string;
  };
  subConsultant?: {
    name: string;
    id: string;
  };
  tutor?: {
    name: string;
    id: string;
  };
  phone?: string;
  email?: string;
  GMTOffset?: number;
  mailingAddress?: TAddressObj;
  deliveryAddress?: TAddressObj;
  administrativeNotes?: string;
  customerDesc?: string;
  customerThunderPoint?: string;
  groups?: [];
  orders?: [];
  students?: ICustomersStudent[];
}

export interface ICreateCampaignsParams {
  name: string;
}

export interface ICreateCampaignsResponse {
  createdAt: number;
  id: string;
  name: string;
  updatedAt: number;
}

export interface ICreateCampaignVisitorsParams {
  campaignId?: string; // 檔次 Id
  visitorName?: string; // 姓名
  mobile?: string; // 手機號碼
  email?: string; // 信箱
  visitorObject?: string; // 檔次訪客
  oneclubId?: string; // oneclub Id
  matchedOneclubId?: string; // 匯入時比對到的 oneclub Id
  contactId?: string; // bitrix24 contact Id
  matchedContactId?: string; // 匯入時比對到的 bitrix24 contact Id
}

export interface IChild {
  name: string; // 姓名
  grade: string; // 學制
  school: string; // 就讀學校
  tutoring: string; // 補習狀況
  remark: string; // 備註
}

export interface ICreateCampaignVisitorsResponse {
  id: string;
  firstName: string; // 名
  lastName: string; // 姓
  attention: string; // 關注程度
  contact: string; // 聯絡人類型
  name: string; // 檔次名稱
  distributionAt: string; // 派發日期
  firstContact: string; // 首次聯絡
  manager: string; // 負責人
  tutor: string; // 輔導老師
  courseType: string; // 上課系統
  companyPhone: string; // 公司電話
  mobile: string; // 行動電話
  fax: string; // 傳真
  phone: string; // 住家電話
  SMS: string; // 簡訊行銷
  otherPhone: string; // 其他號碼
  interviewResult: string; // 電訪結果
  address: string; // 地址
  workMail: string; // 工作電子郵件
  mail: string; // 私人電子郵件
  newsMail: string; // 新聞通訊電子郵件
  otherMail: string; // 其他電子郵件
  channelAccount: string; // open channel 帳戶
  source: string; // 來源
  device: string; // 設備
  notice: string; // 聯繫注意事項
  children: IChild[]; // 小孩
  managerName: string; // 業務名稱
  managerPhone: string; // 業務電話
  isException: boolean; // 是否異常
  comparisonId: string; // 如有異常就有對應的id
}

export interface IImportVisitors {
  isException: number; // 匯入異常
  isSuccess: number; // 匯入成功
  isFail: number; // 匯入失敗
  visitorName: string; // 檔次名稱
}

export enum EPOINT_STATUS {
  ACTIVE = 'active',
  DISABLE = 'disable',
}

const NUMBER = {
  QUANTITY: {
    FIVE: 5,
    HUNDRED: 100,
  },
  TIME: {
    ONE_MIN: 1000 * 60,
  },
};

const KEY = {
  ROLES_PERMISSION: 'ROLES_PERMISSION',
};

const backstageCache = new LRU({
  max: NUMBER.QUANTITY.HUNDRED,
  ttl: NUMBER.QUANTITY.FIVE * NUMBER.TIME.ONE_MIN,
});

enum Folder {
  staff = '/staffs',
  staffBelong = '/staffs/belong',
  user = '/users',
  department = '/departments',
  point = '/points',
  pointRecord = '/point-records',
  auth = '/auth',
  role = '/roles',
  allIDs = '/staffs/allIDs',
}

export default (axios: AxiosInstance, backstage: string) => ({
  // Staff
  /** 取得員工 */
  getStaffs(data: IStaffParams): TApiResponse<IStaffResponse> {
    return axios.get(`${backstage}${Folder.staff}`, { params: data });
  },
  /** 取得部門員工 */
  getBelongStaffs(data: IStaffParams): TApiResponse<IStaffResponse> {
    return axios.get(`${backstage}${Folder.staffBelong}`, { params: data });
  },
  /** GET ALL STAFFS IDs */
  getStaffsId(data: IStaffParams): TApiResponse<IStaffResponse> {
    return axios.get(`${backstage}${Folder.allIDs}`, { params: data });
  },
  /** 更新員工資訊 */
  updateStaff(data: IUpdateStaffParams, id: string): TApiResponse<IStaff> {
    return axios.put(`${backstage}${Folder.staff}/${id}`, data);
  },
  /** 取得個人資料 */
  getMe(): any {
    return axios.get(`${backstage}${Folder.staff}/me`);
  },

  /**
   * 取得個人資料列表
   */
  getStaffList(params: IStaffRecords): TApiResponse<IStaffResponse> {
    return axios.get(`${backstage}${Folder.staff}`, { params });
  },

  // User
  /** 取得使用者資料 */
  // 棄用
  getUser(id: string): any {
    return axios.get(`${backstage}${Folder.user}/${id}`);
  },

  // Department
  createDepartment(data: IDepartmentParams): any {
    return axios.post(`${backstage}${Folder.department}`, data);
  },
  /** 取得部門列表 */
  getDepartmentList(): TApiResponse<IDepartment[]> {
    return axios.get(`${backstage}${Folder.department}`);
  },
  /** 更新部門 */
  updateDepartment(data: IDepartmentParams, id: string): any {
    return axios.put(`${backstage}${Folder.department}/${id}`, data);
  },
  /** 取得部門底下人員 */
  getOwnDepartmentList(
    isOperationDepartment: boolean,
  ): TApiResponse<TDepartmentConsultantData[]> {
    return axios.get(`${backstage}${Folder.department}/own-departments`, {
      params: { isOperationDepartment },
    });
  },
  /** 取得部門底下所有人員 */
  getOwnDepartmentListWithSameLevelStaffs(
    isOperationDepartment: boolean,
  ): TApiResponse<TDepartmentConsultantData[]> {
    return axios.get(
      `${backstage}${Folder.department}/own-departments-staffs`,
      {
        params: { isOperationDepartment },
      },
    );
  },
  /**
   * @description 依照部門層級取得部門列表
   * @param isCounselorDepartment 是否限縮查詢輔導部門
   * @returns {TApiResponse<IDepartment[]>}
   */
  getDepartmentListByLevel(
    isCounselorDepartment: boolean,
  ): TApiResponse<IDepartment[]> {
    return axios.get(`${backstage}${Folder.department}/level`, {
      params: { isCounselorDepartment },
    });
  },

  // Point
  /** 取得user點數 */
  getUserPoints(data: IBackstageUserPoints): TApiResponse<IUserPoints[]> {
    return axios.get(`${backstage}${Folder.point}`, { params: data });
  },
  /** 更新user點數 */
  updateUserPoints(data: IUserPointsParams): TApiResponse<IUserPoints> {
    return axios.patch(`${backstage}${Folder.point}`, data);
  },

  // Point-Records
  /** 取得課堂點數紀錄 */
  getPointsRecords(
    data: IPointsRecords,
    id: string,
  ): TApiResponse<IPointsRecordsRes> {
    return axios.get(`${backstage}${Folder.pointRecord}/${id}`, {
      params: data,
    });
  },

  // Auth
  /** 取得授權token */
  getAuthToken(data: IAuthToken): any {
    return axios.post(`${backstage}${Folder.auth}/signin`, data);
  },
  /** 註冊帳號 */
  registerAccount(data: IRegister): any {
    return axios.post(`${backstage}${Folder.auth}/signup`, data);
  },

  // Roles
  /** 新增角色 */
  createRole(data: IRoleCreate): any {
    return axios.post(`${backstage}${Folder.role}`, data).then((res: any) => {
      if (res.status === 'success') {
        backstageCache.delete(KEY.ROLES_PERMISSION);
      }
      return res;
    });
  },
  /** 取得角色權限 */
  getRolesPermission(): any {
    const cacheKey = KEY.ROLES_PERMISSION;
    if (backstageCache.has(cacheKey)) {
      return backstageCache.get(cacheKey);
    }
    return axios.get(`${backstage}${Folder.role}`).then((res: any) => {
      if (res.status === 'success') {
        backstageCache.set(cacheKey, res);
      }
      return res;
    });
  },
  /** 更新角色權限 */
  updateRolePermission(data: IRolePermission, id: string): any {
    return axios
      .put(`${backstage}${Folder.role}/${id}`, data)
      .then((res: any) => {
        if (res.status === 'success') {
          backstageCache.delete(KEY.ROLES_PERMISSION);
        }
        return res;
      });
  },

  // Customers
  /** 新增客戶資料 */
  createCustomers(data: ICreateCustomersParams): any {
    return axios.post(`${backstage}/customers`, data);
  },

  /** 取得客戶列表 */
  getCustomers(data: IPagnationParams): any {
    return axios.get(`${backstage}/customers`, { params: data });
  },

  /** 查詢客戶 */
  getCustomersByOneClubId(
    oneClubId: string,
  ): TApiResponse<IGetCustomersByOneClubIdResponse> {
    return axios.get(`${backstage}/customers/${oneClubId}`);
  },
  /** 檢查是否有其他相同聯絡人編號的會員 */
  checkOtherContactIdExist(
    oneClubId: string,
    contactId: string,
  ): TApiResponse<{ isExist: boolean }> {
    return axios.get(
      `${backstage}/customers/${oneClubId}/other-customers-exist/${contactId}`,
    );
  },

  /** 更新客戶資料 */
  updateCustomers(
    oneClubId: string,
    data: IUpdateCustomersParams,
  ): TApiResponse<IUpdateCustomers> {
    return axios.patch(`${backstage}/customers/${oneClubId}`, data);
  },

  /** 查詢客戶資料 */
  getCustomerById(oneClubId: string): TApiResponse<any> {
    return axios.get(`${backstage}/customers/${oneClubId}`);
  },
  /** 新增學生資料 */
  createStudent(data: ICreateStudentPayload): any {
    return axios.post(`${backstage}/student`, data);
  },

  /** 取得學員資料 **/
  getStudentById(id: string): TApiResponse<ICustomersStudent> {
    return axios.get(`${backstage}/student/${id}`);
  },

  /** 更新學員資料 **/
  updateStudent(
    data: ICreateStudentPayload,
    id: string,
  ): TApiResponse<ICustomersStudent> {
    return axios.put(`${backstage}/student/${id}`, data);
  },

  /** 建立檔次 **/
  createCampaigns(
    data: ICreateCampaignsParams,
  ): TApiResponse<ICreateCampaignsResponse> {
    return axios.post(`${backstage}/campaigns`, data);
  },

  /** 建立檔次訪客 **/
  createCampaignVisitors(
    data: ICreateCampaignVisitorsParams,
  ): TApiResponse<ICreateCampaignVisitorsResponse> {
    return axios.post(`${backstage}/campaign-visitors`, data);
  },

  /** 匯入檔次 **/
  async importVisitors(file: File): TApiResponse<IImportVisitors> {
    const form = new FormData();
    form.append('file', file);

    return await axios.post(`${backstage}/visitors/importExcel`, form, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },
  /** 檔次列表 **/
  getCampaignVisitors(params: {
    // keyword: string;
    limit: number;
    skip: number;
  }): TApiResponse<{
    isHasException: boolean;
    total: number;
    visitors: ICreateCampaignVisitorsResponse[];
  }> {
    return axios.get(`${backstage}/visitors`, { params });
  },
  /** 檔次異常列表 **/
  getExceptionVisitors(params: { limit: number; skip: number }): TApiResponse<{
    notExceptionVisitors: ICreateCampaignVisitorsResponse[];
    total: number;
    visitors: ICreateCampaignVisitorsResponse[];
  }> {
    return axios.get(`${backstage}/visitors/notException`, { params });
  },
  /** 覆蓋檔次 **/
  overwriteVisitors(id: string): TApiResponse<ICreateCampaignVisitorsResponse> {
    return axios.patch(`${backstage}/visitors/${id}`);
  },
  /** 覆蓋檔次 **/
  skipVisitors(id: string): TApiResponse<ICreateCampaignVisitorsResponse> {
    return axios.delete(`${backstage}/visitors/${id}`);
  },

  /** 匯出學員排課偏好 */
  getStudentPreferenceFile(params: {
    status?: EPOINT_STATUS;
  }): TApiResponse<string> {
    return axios.get(`${backstage}/student/export-schedule-preference`, {
      params,
    });
  },
});
