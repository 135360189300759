import { AxiosInstance } from 'axios';
import { TSBApiResponse } from '@type/common';

export type ExtendedTSBApiResponse<T> = TSBApiResponse<T> & {
  exceptionMessage?: string;
};

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /** 從 Excel 匯入代理人 */
  importSubstituteAgent(file: File): ExtendedTSBApiResponse<null> {
    const form = new FormData();
    form.append('file', file);
    return axios.post(`${apiPrefix}/substitute-agent/import`, form, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },
});
