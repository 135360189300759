import { organizationManagementAuth } from 'constants/auth/auth';
import path, {
  Auth,
  FirstRouteName,
  RouteName,
  organizationPlanRead,
  organizationRead,
} from 'routes/path';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { ICONS } from './icon';

const generateOrganizationManagementNav = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(organizationManagementAuth)) {
    return null;
  }

  return {
    title: RouteName.organizationmanagement,
    path: path.ORGANIZATION_MANAGEMENT,
    icon: ICONS.tutorialCenter,
    children: (() => {
      const newNav = [];
      includeAuth(organizationRead) &&
        newNav.push({
          title: FirstRouteName.organizations,
          path: path.ORGANIZATION_LIST,
        });

      includeAuth(organizationPlanRead) &&
        newNav.push({
          title: FirstRouteName.plans,
          path: path.PLANS_LIST,
        });

      return newNav;
    })(),
  };
};

export { generateOrganizationManagementNav };
