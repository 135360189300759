import { type AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import {
  GetExchangeItemsByOwnerIdParamsDTO,
  GetExchangeItemsByOwnerIdResponseDTO,
} from './type';
export default (axios: AxiosInstance, apiPrefix: string) => ({
  getExchangeItemsByOwnerId(
    params: GetExchangeItemsByOwnerIdParamsDTO,
  ): TApiResponse<GetExchangeItemsByOwnerIdResponseDTO> {
    return axios.get(`${apiPrefix}/backend/exchange-items-records`, { params });
  },
});
