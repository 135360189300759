import { AxiosInstance } from 'axios';
import { TApiResponse } from 'src/@type/common';
import {
  PostDurationSchedulesPayload,
  PostDurationSchedulesResponse,
} from './type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /**
   * 新增班表：重複排班/單次排班
   */
  postDurationSchedules(
    ownerId: string,
    data: PostDurationSchedulesPayload,
  ): TApiResponse<PostDurationSchedulesResponse> {
    return axios.post(`${apiPrefix}/mms/schedules/durations/${ownerId}`, data);
  },
});
